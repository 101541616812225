import { Outlet } from "react-router-dom";
import { Suspense } from "react";

import { NewAdminNav } from "../AdminNav/NewAdminNav";
import { AuthProvider } from "../../../context/authContext";

import style from "./Admin.module.css";
import { AdminAlertProvider } from "../../../context/adminAlertContext";
import AdminAlert from "../Alert/AdminAlert";
import LinearLoader from "./../LinearLoader/LinearLoader";
import ThemeProvider from "../../../MUITheme/ThemeProvider";

const Admin = () => {
    return (
        <AuthProvider>
            <AdminAlertProvider>
                <ThemeProvider>
                    <div className={style.section}>
                        <AdminAlert />
                        <NewAdminNav></NewAdminNav>
                        <Suspense fallback={<LinearLoader />}>
                            <Outlet></Outlet>
                        </Suspense>
                    </div>
                </ThemeProvider>
            </AdminAlertProvider>
        </AuthProvider>
    );
};

export default Admin;
