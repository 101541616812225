import * as request from "./requester";

let baseUrl = `${process.env.REACT_APP_SERVER_URL}/riddle`;

export const getAll = () => request.get(baseUrl);

export const getOne = (name) => request.get(`${baseUrl}/${name}`);

export const createRiddle = (data) => request.post(baseUrl, data);

export const editRiddle = (id, data) => request.put(`${baseUrl}/admin/${id}`, data);

export const getRiddleById = (id) => request.get(`${baseUrl}/admin/${id}`);
