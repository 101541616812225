export const overrides = (theme) => {
    return {
        MuiListItemButton: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    ...(ownerState.variant === "nav-button" && {
                        borderRadius: "10px",
                        "&.Mui-selected": {
                            color: "rgb(0, 41, 80)",
                            backgroundColor: "rgba(0, 84, 164, 0.5)",
                            "&:hover": {
                                backgroundColor: "rgba(0, 84, 164, 0.5)", // Ensures no default hover effect
                            },
                        },
                    }),
                }),
            },
        },
    };
};
