import React, { useState, useEffect, useRef } from "react";

import style from "./QuickFacts.module.css";

export const QuickFacts = () => {
    const [displayElements, setDisplayElements] = useState(null);

    const imageRef = useRef(null);
    const imageRefMobile = useRef(null);

    const [revealClipPath, setRevealClipPath] = useState("polygon(0 0, 0% 0, 0% 100%, 0 100%)");

    const calculateLineView = (ref) => {
        if (ref.current) {
            const offset = 300; // Set this to the desired offset value
            const imageRect = ref.current.getBoundingClientRect();
            const imageTop = imageRect.top + offset;
            const imageBottom = imageRect.bottom;

            if (imageTop < window.innerHeight && imageBottom > 0) {
                // Image is in the viewport (with offset consideration)
                const imageVisibleHeight = Math.max(window.innerHeight - imageTop, 0);
                const imageTotalHeight = imageRect.height;
                const percentageRevealed = (imageVisibleHeight / imageTotalHeight) * 100;
                setRevealClipPath(`polygon(0 0, ${percentageRevealed}% 0, ${percentageRevealed}% 100%, 0 100%)`);
            } else if (imageBottom <= 0) {
                // Image is above the viewport
                setRevealClipPath("polygon(0 0, 0 0, 0 100%, 0 100%)");
            } else if (imageTop >= window.innerHeight) {
                // Image is below the viewport
                setRevealClipPath("polygon(0 0, 0 0, 0 0, 0 0)");
            }
        }
    };
    const handleScroll = () => {
        if (imageRef.current) calculateLineView(imageRef);

        if (imageRefMobile.current) calculateLineView(imageRefMobile);
    };

    const displayElementsDependingOnScreenSize = () => {
        if (window.innerWidth > 900) {
            setDisplayElements(false);
        } else if (window.innerWidth <= 900) {
            setDisplayElements(true);
        }
    };

    useEffect(() => {
        displayElementsDependingOnScreenSize();

        window.addEventListener("scroll", handleScroll);
        window.addEventListener("resize", displayElementsDependingOnScreenSize);
        return () => {
            window.removeEventListener("scroll", handleScroll);
            window.removeEventListener("resize", displayElementsDependingOnScreenSize);
        };
    }, []);

    const mobileVisualElements = () => {
        return (
            <div className={`${style.visualElementsWrapper} `}>
                <div
                    className={`${style.lineWrapper}`}
                    ref={imageRefMobile}
                    style={{
                        position: "absolute",
                        top: "1.5rem",
                        left: 0,
                    }}>
                    <img
                        src='/line1-mobile.svg'
                        alt='Scroll reveal'
                        style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            // clipPath: "polygon(0 0, 0 0, 0 100%, 0 100%)",
                            clipPath: revealClipPath,
                        }}
                    />
                </div>
                <img
                    className={style.buildingImg}
                    src='/big-building-homepage1.png'
                    alt='homepage second old building'></img>
            </div>
        );
    };

    const desktopVisualElements = () => {
        return (
            <div className={`${style.visualElementsWrapper} `}>
                <div
                    className={`${style.lineWrapper} `}
                    ref={imageRef}
                    style={{
                        position: "absolute",
                        top: "-45rem",
                        left: 0,
                    }}>
                    <img
                        loading='lazy'
                        src='/line1.svg'
                        alt='Scroll reveal'
                        style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            clipPath: revealClipPath,
                            // clipPath: "polygon(0 0, 0 0, 0 100%, 0 100%)",
                        }}
                    />
                </div>

                <img
                    className={style.buildingImg}
                    src='/big-building-homepage1.png'
                    alt='homepage second old building'></img>
                <div className={style.backgroundMask}></div>
            </div>
        );
    };

    const displayShortInfo = () => {
        return (
            <div className={`${style.shortInfo} `}>
                <p>
                    Над 40 авантюристи вече откриха, че улиците на града могат да бъдат нещо повече от място за
                    паркиране
                </p>
            </div>
        );
    };

    return (
        <>
            {displayElements ? mobileVisualElements() : null}
            <div className={style.quickFactsSection}>
                <svg width='0' height='0'>
                    <defs>
                        <clipPath id='even-mask' clipPathUnits='objectBoundingBox'>
                            <path
                                d='M0,0 L0,1
                            C0.03,1 0.07,0.97 0.12,0.96
                            C0.17,0.96 0.20,0.98 0.25,0.976
                            C0.29,0.96 0.33,0.99 0.40,0.985
                            C0.47,0.97 0.52,1 0.54,0.985
                            C0.59,0.975 0.58,0.97 0.62,0.97
                            C0.66,0.965 0.69,0.99 0.73,0.99
                            C0.76,0.99 0.77,0.97 0.90,0.97
                            C0.96,0.97 0.96,1 1,1
                            
                            L1,0
                            
                            C0.96,0.03 0.92,0.08 0.88,0.05 
                            C0.85,0.02 0.82,0.02 0.78,0.04
                            C0.75,0.06 0.72,0.08 0.67,0.04
                            C0.66,0.03 0.61,0.00 0.59,0.02
                            C0.55,0.05 0.50,0.00 0.47,0.04
                            C0.44,0.08 0.39,0.06 0.36,0.03
                            C0.325,0.00 0.23,0.08 0.18,0.04
                            C0.15,0.01 0.02,0.00 0,0.02

                             Z               
                            
                            '></path>
                        </clipPath>
                    </defs>
                </svg>
                {displayElements ? displayShortInfo() : null}
                {/* <div className={`${style.shortInfo} ${style.mobile}`}>
                    <p>
                        Над 40 авантюристи вече откриха, че улиците на града могат да бъдат нещо повече от място за
                        паркиране
                    </p>
                </div> */}
                <div className={style.quickFactsContentWrapper}>
                    <div className={style.row}>
                        <div className={style.iconDiv}>
                            <img className={style.icon} alt='puzzle' src='/puzzle.svg'></img>
                        </div>
                        <div className={style.textWrapper}>
                            <p className={style.value}>15+</p>
                            <p className={style.indicator}>изиграни игри</p>
                        </div>
                    </div>
                    <div className={style.row}>
                        <div className={style.iconDiv}>
                            <img className={style.icon} alt='clock' src='/clock.svg'></img>
                        </div>
                        <div className={style.textWrapper}>
                            <p className={style.value}>3 ч.</p>
                            <p className={style.indicator}>средно време</p>
                        </div>
                    </div>
                    <div className={style.row}>
                        <div className={style.iconDiv}>
                            <img className={style.icon} alt='win' src='/bubble.svg'></img>
                        </div>
                        <div className={style.textWrapper}>
                            <p className={style.value}>300</p>
                            <p className={style.indicator}>научени факти</p>
                        </div>
                    </div>
                </div>
                {displayElements ? null : displayShortInfo()}
                {!displayElements ? desktopVisualElements() : null}

                {/* <div className={`${style.shortInfo} ${style.desktop}`}>
                    <p>
                        Над 40 авантюристи вече откриха, че улиците на града могат да бъдат нещо повече от място за
                        паркиране
                    </p>
                </div> */}
            </div>
        </>
    );
};
