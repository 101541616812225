import { createTheme, CssBaseline, ThemeProvider as MUIThemeProvider } from "@mui/material";
import { useMemo } from "react";

import { palette } from "./palette";
import { overrides } from "./overrides";
import { typography } from "./typography";
const ThemeProvider = ({ children }) => {
    const memoizedValue = useMemo(
        () => ({
            palette: palette(),
            typography,
        }),
        []
    );
    const theme = createTheme(memoizedValue);

    theme.components = overrides(theme);

    return (
        <MUIThemeProvider theme={theme}>
            <CssBaseline />
            {children}
        </MUIThemeProvider>
    );
};

export default ThemeProvider;
