import { Link } from "react-router-dom";
import { useState, useRef } from "react";
import validator from "validator";

import { Checkbox } from "./../Common/Checkbox/Checkbox";
import * as contactService from "./../../service/contactService";

import style from "./Footer.module.css";

export const Footer = () => {
    const [shakePrivacy, setShakePrivacy] = useState(false);
    const [isCheckedPrivacy, setIsCheckedPrivacy] = useState(false);
    const [shakeMarketing, setShakeMarketing] = useState(false);
    const [isCheckedMarketing, setIsCheckedMarketing] = useState(false);
    const [resetCheckbox, setResetCheckbox] = useState(false);
    const [contentLength, setContentLength] = useState(0);
    const [sendingForm, setSendingForm] = useState(false);

    const [input, setInput] = useState({ subject: "", content: "", sender: "", name: "", second_name: "" });
    const [formState, setFormState] = useState("");

    const textareaRef = useRef(null);

    const inputHandler = (e) => {
        const oldInput = { ...input };
        if (e.target.name === "content") setContentLength(e.target.value.length);
        if (e.target.name === "content" && e.target.value.length >= 500) return;
        oldInput[e.target.name] = e.target.value;
        setInput(oldInput);
    };

    const updateCharacterCountAndScroll = (event) => {
        // const newText = event.target.value;
        // setText(newText);

        // Automatically scroll to the bottom
        if (textareaRef.current) {
            textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
        }
    };
    const sendContact = () => {
        if (validator.isEmail(input.sender)) {
            if (checkCheckboxes()) {
                setSendingForm(true);
                contactService
                    .makeContact({ ...input, useForMarketing: isCheckedMarketing })
                    .then((res) => {
                        if (res.status === "error") alert(res.message);
                        setInput({ subject: "", content: "", sender: "", name: "", second_name: "" });
                        setFormState("success");
                        setResetCheckbox((prev) => !prev);
                        setSendingForm(false);
                    })
                    .catch((err) => alert(err));
            }
        } else setFormState("error");
    };

    const checkCheckboxes = () => {
        if (isCheckedPrivacy) return true;

        if (!isCheckedPrivacy) setShakePrivacy(true);
        return false;
    };

    return (
        <div className={style.footer}>
            <div className={style.wrapper}>
                <div className={`${style.leftSection} ${style.desktop}`}>
                    <div className={style.logoSection}>
                        <img className={style.logo} alt='logo' src='/logo-primary-row-white.svg'></img>
                    </div>
                    <p className={style.slogan}>
                        Кой каза, че историята <br></br>трябва да е скучна?
                    </p>
                    <div className={style.linksWrapper}>
                        <div className={style.leftLinks}>
                            {/* <Link className={style.link} to='/' >
                                Начало
                            </Link> */}
                            <Link className={style.link} to='/'>
                                Начало
                            </Link>
                            {/* <Link className={style.link} to='/catalogue' >
                                Загадки
                            </Link> */}
                            <Link className={style.link} to='/catalogue'>
                                Загадки
                            </Link>
                            <Link className={style.link} to='/faq'>
                                Въпроси и отговори
                            </Link>
                            <Link className={style.link} to='/rules'>
                                Правила на играта
                            </Link>
                            <div className={style.socialWrapper}>
                                <a href='https://www.facebook.com/profile.php?id=61556396611643'>
                                    <img className={style.socialButton} src='/facebook.svg' alt='facebook'></img>
                                </a>
                                <a href='https://www.instagram.com/igritenagrada/'>
                                    <img className={style.socialButton} src='/instagram.svg' alt='instagram'></img>
                                </a>
                            </div>
                        </div>
                        <div className={style.rightLinks}>
                            <Link className={style.link} to='/terms'>
                                Общи условия
                            </Link>
                            <Link className={style.link} to='/privacy'>
                                Политика за поверителност
                            </Link>
                            <Link className={style.link} to='/cookies'>
                                Политика за бисквитки
                            </Link>
                        </div>
                    </div>
                    <p className={style.reserved}>Всички права запазени @ Игрите на града 2024</p>
                </div>
                <div className={style.rightSection}>
                    <div className={style.formSection}>
                        <div className={`${style.logoSection} ${style.mobile}`}>
                            <img className={style.logo} alt='logo' src='logo-primary-row-white.svg'></img>
                        </div>

                        <p className={`${style.slogan} ${style.mobile}`}>
                            Кой каза, че историята <br></br>трябва да е скучна?
                        </p>
                        <p className={style.formTitle}>Искаш да се свържеш с нас?</p>

                        <div className={style.inputRow}>
                            <input
                                className={`${style.inputContact}`}
                                name='name'
                                value={input.name}
                                onFocus={() => setFormState("")}
                                onChange={(e) => inputHandler(e)}
                                placeholder='Име'></input>
                            {/* <input
                                className={`${style.inputContact} ${style.inputContactRow}`}
                                name='second_name'
                                value={input.second_name}
                                onChange={(e) => inputHandler(e)}
                                onFocus={() => setFormState("")}
                                placeholder='Фамилия'></input> */}
                        </div>
                        <input
                            className={style.inputContact}
                            name='sender'
                            value={input.sender}
                            onFocus={() => setFormState("")}
                            onChange={(e) => inputHandler(e)}
                            placeholder='Твоят имейл тук'></input>

                        <input
                            className={style.inputContact}
                            name='subject'
                            placeholder='Тема'
                            value={input.subject}
                            onFocus={() => setFormState("")}
                            onChange={(e) => inputHandler(e)}></input>
                        <div className={style.textAreaSection}>
                            <div className={style.textAreaWrapper}>
                                <textarea
                                    ref={textareaRef}
                                    className={`${style.inputContact} ${style.textareaContact}`}
                                    name='content'
                                    value={input.content}
                                    onChange={(e) => {
                                        updateCharacterCountAndScroll(e);
                                        inputHandler(e);
                                    }}
                                    onFocus={() => setFormState("")}
                                    placeholder='Въпрос'></textarea>
                            </div>
                            <p className={style.contentLength}>{contentLength}/500</p>
                        </div>

                        {formState === "error" ? (
                            <p className={`${style.formMessage} ${style.errorMessage}`}>Въведете валиден имейл!</p>
                        ) : null}
                        {formState === "success" ? (
                            <p className={`${style.formMessage} ${style.successMessage}`}>
                                Успешно изпратихте формата!
                            </p>
                        ) : null}

                        <div className={style.checkboxSection}>
                            <div className={style.checkboxWrapper}>
                                <Checkbox
                                    shake={shakeMarketing}
                                    setShake={setShakeMarketing}
                                    isChecked={isCheckedMarketing}
                                    setIsChecked={setIsCheckedMarketing}
                                    reset={resetCheckbox}
                                    label='marketing-footer'
                                    text='Съгласен съм данните ми да бъдат използвани за маркетингови цели'></Checkbox>

                                <Checkbox
                                    shake={shakePrivacy}
                                    setShake={setShakePrivacy}
                                    isChecked={isCheckedPrivacy}
                                    setIsChecked={setIsCheckedPrivacy}
                                    reset={resetCheckbox}
                                    label='terms-footer'
                                    haveLink={true}
                                    pointA={38}
                                    pointB={65}
                                    link='https://igritenagrada.com/privacy'
                                    linkColor='yellow'
                                    text='Прочетох и разбрах условията в секция "Правила за поверителност"'></Checkbox>
                            </div>

                            <div className={`${style.sendButtonWrapper} ${sendingForm ? style.sendingForm : null}`}>
                                <div className={`${style.sendButton}`} onClick={() => sendContact()}>
                                    <img className={style.sendButtonArrow} alt='arrow' src='/blue-arrow.svg'></img>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${style.leftSection} ${style.mobile}`}>
                    <p className={style.linksTitle}> Бързи връзки</p>
                    <div className={style.linksWrapper}>
                        <div className={style.leftLinks}>
                            <Link className={style.link} to='/'>
                                Начало
                            </Link>
                            <Link className={style.link} to='/catalogue'>
                                Загадки
                            </Link>
                            <Link className={style.link} to='/faq'>
                                Въпроси и отговори
                            </Link>
                            <Link className={style.link} to='rules'>
                                Правила на играта
                            </Link>
                            <div className={`${style.rightLinks} ${style.mobile}`}>
                                <Link className={style.link} to='/terms'>
                                    Общи условия
                                </Link>
                                <Link className={style.link} to='/privacy'>
                                    Политика за поверителност
                                </Link>
                                <Link className={style.link} to='/cookies'>
                                    Политика за бисквитки
                                </Link>
                            </div>
                            <div className={style.socialWrapper}>
                                <a
                                    className={style.socialLink}
                                    href='https://www.facebook.com/profile.php?id=61556396611643'>
                                    <img className={style.socialButton} src='/facebook.svg' alt='facebook'></img>
                                </a>

                                <a href='https://www.instagram.com/igritenagrada/'>
                                    <img className={style.socialButton} src='/instagram.svg' alt='instagram'></img>
                                </a>
                            </div>
                        </div>
                        <div className={`${style.rightLinks} ${style.desktop}`}>
                            <Link className={style.link} to='terms'>
                                Общи условия
                            </Link>
                            <Link className={style.link} to='privacy'>
                                Политика за поверителност
                            </Link>
                            <Link className={style.link} to='cookies'>
                                Политика за бисквитки
                            </Link>
                        </div>
                    </div>
                    <p className={style.reserved}>Всички права запазени @ Игрите на града 2024</p>
                </div>
            </div>
        </div>
    );
};
