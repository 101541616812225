import { useRef, useState, useEffect } from "react";

import { Button } from "../../Common/Button/Button";
import { CommentSLider } from "./CommentSlider/CommentSlider";

import style from "./CommentSection.module.css";

export const CommentSection = () => {
    const [highlighter3, setHighlighter3] = useState(true);
    const [highlighter4, setHighlighter4] = useState(true);
    const highlighterRef3 = useRef(null);
    const highlighterRef4 = useRef(null);

    const highlight = (ref, setHighlight, offset) => {
        if (ref.current) {
            const imageRect = ref.current.getBoundingClientRect();
            const imageTop = imageRect.top + offset;
            const imageBottom = imageRect.bottom;

            if (imageTop < window.innerHeight && imageBottom > 0) {
                // Image is in the viewport (with offset consideration)
                setHighlight(false);
            }
        }
    };

    const handleScroll = () => {
        if (highlighter3) highlight(highlighterRef3, setHighlighter3, 200);
        if (highlighter4) highlight(highlighterRef4, setHighlighter4, 200);
    };
    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <>
            <div className={style.titleWrapper}>
                <h2 className={style.title} ref={highlighterRef3}>
                    А ето какво{" "}
                    <span className={style.highlighterSpan}>
                        казват
                        <div className={`${style.highlighter} ${highlighter3 ? style.hide : null}`}></div>
                    </span>{" "}
                    <br />
                    някои от отборите
                </h2>
            </div>

            <div className={style.commentAction}>
                <CommentSLider></CommentSLider>
            </div>

            <div className={style.redirectionWrapper}>
                <div className={style.title} ref={highlighterRef4}>
                    Готов ли си да{" "}
                    <span className={style.highlighterSpan}>
                        играеш ?<div className={`${style.highlighter} ${highlighter4 ? style.hide : null}`}></div>
                    </span>
                </div>
                <p className={style.redirectionText}>Грабни своя билет за влака към градското минало</p>
                <div className={style.desktop}>
                    <Button navigate={"/catalogue"} version={"version-1"}></Button>
                </div>
                <div className={style.mobile}>
                    <Button navigate={"/catalogue"} version={"version-9"}></Button>
                </div>
            </div>
        </>
    );
};
