import PropTypes from "prop-types";
import { Button } from "./../../../Common/Button/Button";

import style from "./FunFact.module.css";

export const FunFact = ({ skipState, funFactInfo, changeFunFactState }) => {
    return (
        <div className={style.funFactSection}>
            <div className={style.funFactWrapper}>
                <div className={style.logoSection}>
                    <img className={style.logo} alt='logo' src='/logo-primary-row-white.svg'></img>
                </div>
                {skipState ? (
                    <div className={style.skipWrapper}>
                        <div className={style.text}>
                            <span className={style.span}>Напътствия към правилния отговор: </span>
                            {funFactInfo.guidelines.split("\n").map((line, index) => (
                                <p key={index}>
                                    {line}
                                    <br />
                                </p>
                            ))}
                        </div>
                        <p className={style.text}>
                            <span className={style.span}>Отговор:</span> {funFactInfo.answer}
                        </p>
                    </div>
                ) : null}

                <div className={style.funFact}>
                    {!skipState ? <p className={style.span}>Поздравления, дадохте правилен отговор!</p> : null}
                    <div className={style.text}>
                        <span className={style.span}>Знаете ли че?</span>
                        {funFactInfo.fun_fact.split("\n").map((line, index) => (
                            <p key={index}>
                                {line}
                                <br />
                            </p>
                        ))}
                    </div>
                </div>
                <div className={style.buttonWrapper}>
                    <Button onClick={changeFunFactState} version='version-3' text='Напред'></Button>
                </div>
            </div>
        </div>
    );
};

FunFact.propTypes = {
    skipState: PropTypes.bool.isRequired,
    funFactInfo: PropTypes.object.isRequired,
    changeFunFactState: PropTypes.func.isRequired,
};
