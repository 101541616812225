import React, { useState, useEffect, useRef } from "react";

import style from "./IntroductionSection.module.css";

export const IntroductionSection = () => {
    const [highlighter1, setHighlighter1] = useState(true);
    const [highlighter2, setHighlighter2] = useState(true);
    const [contentPosition, setContentPosition] = useState(false);

    const highlighterRef1 = useRef(null);
    const highlighterRef2 = useRef(null);

    const highlight = (ref, setHighlight, offset) => {
        if (ref.current) {
            const imageRect = ref.current.getBoundingClientRect();
            const imageTop = imageRect.top + offset;
            const imageBottom = imageRect.bottom;

            if (imageTop < window.innerHeight && imageBottom > 0) {
                // Image is in the viewport (with offset consideration)
                setHighlight(false);
            }
        }
    };
    const handleScroll = () => {
        if (highlighter1) highlight(highlighterRef1, setHighlighter1, 400);
        if (highlighter2) highlight(highlighterRef2, setHighlighter2, 500);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        window.addEventListener("resize", displayContent);

        displayContent();
        return () => {
            window.removeEventListener("scroll", handleScroll);
            window.removeEventListener("resize", displayContent);
        };
    }, []);

    const displayContent = () => {
        if (window.innerWidth <= 900) {
            setContentPosition(true);
        } else {
            setContentPosition(false);
        }
    };

    const contentMobile = () => {
        return (
            <div className={style.text} ref={highlighterRef2}>
                Потопи се в градска игра{" "}
                <span className={style.highlighterSpan}>
                    на открито,
                    <div className={`${style.highlighter} ${highlighter1 ? style.hide : null}`}></div>
                </span>{" "}
                където историческите факти оживяват. Преживей историята около теб по иновативен начин.
            </div>
        );
    };

    const contentDesktop = () => {
        return (
            <div className={style.text} ref={highlighterRef2}>
                Потопи се в увлекателната ни градска игра{" "}
                <span className={style.highlighterSpan}>
                    на открито,
                    <div className={`${style.highlighter} ${highlighter1 ? style.hide : null}`}></div>
                </span>{" "}
                където историческите факти оживяват. Съчетавайки забавлението от „нещотърсачеството“ с любопитни
                исторически откровения за твоя град, предлагаме иновативен начин{" "}
                <span className={style.highlighterSpan}>
                    да преживееш
                    <div className={`${style.highlighter} ${highlighter2 ? style.hide : null}`}></div>
                </span>{" "}
                историята около теб.
            </div>
        );
    };

    return (
        <>
            <div ref={highlighterRef1}></div>
            {contentPosition ? contentMobile() : contentDesktop()}
        </>
    );
};
