import { Box, LinearProgress } from "@mui/material";
const LinearLoader = () => {
    return (
        <Box display='flex' alignItems='center' justifyContent='center' sx={{ width: "100%", height: "100vh" }}>
            <Box sx={{ width: "40%", my: 2 }}>
                <LinearProgress />
            </Box>
        </Box>
    );
};

export default LinearLoader;
