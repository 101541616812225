import { Button } from "./../../Common/Button/Button";

import style from "./CatalogueItem.module.css";

export const CatalogueItem = ({ riddle }) => {
    return (
        <div className={style.itemWrapper}>
            <h2 className={style.itemTitle}>{riddle.title}</h2>
            <div className={style.riddleInfoWrapper}>
                <div className={style.leftSection}>
                    <div className={style.rowWrapper}>
                        <div className={style.row}>
                            <p className={style.rowKey}>Град</p>
                            <p className={style.value}>{riddle.city}</p>
                        </div>
                        <div className={style.row}>
                            <p className={style.rowKey}>Локация</p>
                            <p className={style.value}>{riddle.location}</p>
                        </div>
                        <div className={style.row}>
                            <p className={style.rowKey}>Време</p>
                            <p className={style.value}>{riddle.duration}</p>
                        </div>
                        <div className={style.row}>
                            <p className={style.rowKey}>Разстояние</p>
                            <p className={style.value}>{riddle.distance}</p>
                        </div>
                        <div className={style.row}>
                            <p className={style.rowKey}>Брой въпроси</p>
                            <p className={style.value}>{riddle.nQuestions}</p>
                        </div>
                        <div className={style.row}>
                            <p className={style.rowKey}>Оптимални играчи</p>
                            <p className={style.value}>{riddle.people}</p>
                        </div>
                        <div className={style.row}>
                            <p className={style.rowKey}>Сложност</p>
                            <p className={style.value}>{riddle.difficulty}</p>
                        </div>
                    </div>
                </div>
                <div className={style.rightSection}>
                    <img alt='riddle' className={style.riddleImage} src={riddle.picture}></img>
                </div>
            </div>
            {riddle.description.split("\n").map((line, index) => (
                <p className={style.riddleDescription} key={index}>
                    {line}
                    <br />
                </p>
            ))}

            <p className={style.riddleDescription}></p>
            <div className={style.priceSection}>
                {riddle.discount !== 0 ? (
                    <p className={style.price}>
                        Цена: {(riddle.price - (riddle.price * riddle.discount) / 100) / 100}лв.{" "}
                        <span className={style.crossPrice}>{riddle.price / 100}лв</span>{" "}
                    </p>
                ) : (
                    <p className={style.price}>Цена: {riddle.price / 100}лв.</p>
                )}

                <div className={style.desktop}>
                    <Button navigate={`/checkout/${riddle.url}`} text='Купи' version='version-2'></Button>
                </div>
                <div className={style.mobile}>
                    <Button navigate={`/checkout/${riddle.url}`} text='Купи' version='version-10'></Button>
                </div>
            </div>
        </div>
    );
};
