import style from "./GeneralBackground.module.css";

import { Button } from "./../../../Common/Button/Button";

export const GeneralBackground = (params) => {
    return (
        <div className={style.generalBackgroundSection}>
            <div className={style.generalBackgroundWrapper}>
                <div className={style.logoSection}>
                    <img className={style.logo} alt='logo' src='/logo-primary-row-white.svg'></img>
                </div>
                <div className={style.titleWrapper}>
                    {/* {console.log(params.event.event.riddle.title)} */}
                    <p className={style.title}>{params.event.event.riddle.title}</p>
                    <div className={style.titleUnderline}></div>
                </div>

                <div className={style.textWrapper}>
                    {params.event.event.riddle.starting_background.split("\n").map((line, index) => (
                        <p className={style.text} key={index}>
                            {line}
                            <br />
                        </p>
                    ))}
                </div>
                <div className={style.warningDiv}>
                    <p className={style.warning}>
                        <span className={style.bold}>Важно:</span> Общият брой подсказки (хинтове) за тази игра е{" "}
                        {params.event.event.riddle.max_hints}.
                    </p>
                </div>
                <div className={style.buttonWrapper}>
                    <Button onClick={() => params.setStage(2)} version='version-3' text='Напред'></Button>
                </div>
            </div>
        </div>
    );
};
