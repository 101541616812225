import * as request from "./requester";

let baseUrl = `${process.env.REACT_APP_SERVER_URL}/company`;

export const getOne = (company) => request.get(`${baseUrl}/${company}`);

export const updateCompany = (id, data) => request.patch(`${baseUrl}/company/${id}`, data, true);

export const getOneCompanyById = (id) => request.get(`${baseUrl}/company/${id}`);

export const getAll = () => request.get(`${baseUrl}`);

export const create = (data) => request.post(`${baseUrl}`, data, true);

export const deleteCompanyById = (id) => request.del(`${baseUrl}/company/${id}`);
