import { Link } from "react-router-dom";
import { Button } from "../../Common/Button/Button";
import PropTypes from "prop-types";

import style from "./EndStage.module.css";

export const EndStage = ({ eventData }) => {
    let intoMinutes = eventData.timeDelta / 60;
    let minutes = String(Math.floor(intoMinutes % 60)).padStart(2, "0");
    let hours = String(Math.floor(intoMinutes / 60)).padStart(2, "0");
    let sec = String(Math.floor(eventData.timeDelta % 60)).padStart(2, "0");

    return (
        <div className={style.endSection}>
            <div className={style.endWrapper}>
                <div className={style.logoSection}>
                    {" "}
                    <img className={style.logo} alt='logo' src='/logo-primary-row-white.svg'></img>
                </div>
                <p className={style.title}>Завършихте успешно :)</p>
                <p className={style.text}>{eventData.event.riddle.end_story}</p>
                <div className={style.test1}>
                    <img src='/end-stage-wave.png' alt='end stage wave' className={style.endStageWave}></img>
                </div>

                <div className={style.statistic}>
                    <p className={style.indicators}>общо въпроси</p>
                    <p className={style.indicators}>{eventData.event.riddle.nQuestions}</p>

                    <p className={style.indicators}>правилни отговори</p>
                    <p className={style.indicators}>{eventData.event.guessed_questions}</p>

                    <p className={style.indicators}>пропуснати</p>
                    <p className={style.indicators}>{eventData.event.skipped_questions}</p>

                    <p className={style.indicators}>използвани хинтове</p>
                    <p className={style.indicators}>{eventData.event.number_used_hints}</p>

                    <p className={style.indicators}>време</p>
                    <p className={style.indicators}>
                        {hours}:{minutes}:{sec} ч.
                    </p>

                    <div className={style.importantStats}>
                        <p className={style.indicators}>точки</p>
                        <p className={`${style.indicators} ${style.bold}`}>
                            {Math.round(eventData.event.points * 10) / 10} т./ {eventData.event.riddle.total_points} т.
                        </p>

                        <p className={style.indicators}>място в класацията</p>
                        <p className={`${style.indicators} ${style.bold}`}>{eventData.rank_position}</p>
                    </div>
                </div>

                <div className={style.buttonWrapper}>
                    <Link
                        className={style.aButton}
                        to={`/rank/${eventData.event.riddle.title}${eventData.event.company != undefined ? `/${eventData.event.company}` : ""}/${
                            eventData.rank_position
                        }`}>
                        <Button version={"version-4"} text='Класация'></Button>
                    </Link>
                    <Link className={style.anc} to='https://docs.google.com/forms/d/e/1FAIpQLScN40yHXCBsXVS6nJGM6NW2aX4C0WJJK8zlNT41c0qx0Gx0Vg/viewform?usp=sf_link'>
                        Анкета
                    </Link>
                </div>
            </div>
        </div>
    );
};

EndStage.propTypes = {
    eventData: PropTypes.object.isRequired,
};
