import { useEffect, useState } from "react";

import style from "./Tutorial.module.css";

export const Tutorial = (params) => {
    const [stage, setState] = useState(1);
    const [end, setEnd] = useState(false);

    useEffect(() => {
        if (stage >= 9) {
            setEnd(true);
        }
    }, [stage]);

    const imageContent = (number) => {
        return <img className={style.image} src={`/screen0${number}.webp`}></img>;
    };
    return (
        <div className={style.tutorialWrapper}>
            {imageContent(stage)}
            {end === false ? (
                <button className={style.button} onClick={() => setState((prev) => prev + 1)}>
                    Продължи
                </button>
            ) : (
                <button className={style.button} onClick={() => params.start()}>
                    Старт
                </button>
            )}
        </div>
    );
};
