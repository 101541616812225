import React, { createContext, useState, useContext } from "react";

const AlertContext = createContext();

export const AdminAlertProvider = ({ children }) => {
    const [alert, setAlert] = useState({ open: false, message: "", severity: "success" });

    const showAlert = (message, severity = "success") => {
        setAlert({ open: true, message, severity });
    };

    const closeAlert = () => {
        setAlert({ open: false, message: "", severity: "success" });
    };

    return <AlertContext.Provider value={{ alert, showAlert, closeAlert }}>{children}</AlertContext.Provider>;
};

export const useAlert = () => useContext(AlertContext);
