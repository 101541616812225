import ApartmentIcon from "@mui/icons-material/Apartment";
import ContactEmergencyIcon from "@mui/icons-material/ContactEmergency";
import DiscountIcon from "@mui/icons-material/Discount";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import PaymentIcon from "@mui/icons-material/Payment";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import LogoDevIcon from "@mui/icons-material/LogoDev";

export const navConfig = {
    riddles: {
        icon: SmartToyIcon,
        paths: ["/admin/riddles", "/admin/riddles/add"],
        urls: [
            {
                url: "/admin/riddles",
                text: "Всички",
            },
            { url: "/admin/riddles/add", text: "Добави" },
        ],
        text: "Загадки",
    },
    orders: {
        icon: PaymentIcon,
        paths: ["/admin/orders"],
        urls: [
            {
                url: "/admin/orders",
                text: "Всички",
            },
        ],
        text: "Покупки",
    },
    events: {
        icon: EmojiEventsIcon,
        paths: ["/admin/events"],
        urls: [
            {
                url: "/admin/events",
                text: "Всички",
            },
            {
                url: "/admin/events/create",
                text: "Създай",
            },
        ],
        text: "Евенти",
    },
    discounts: {
        icon: DiscountIcon,
        paths: ["/admin/discounts", "/admin/discounts/add"],
        urls: [
            {
                url: "/admin/discounts",
                text: "Всички",
            },
            { url: "/admin/discounts/add", text: "Добави" },
        ],
        text: "Код за намаление",
    },
    faqs: {
        icon: LiveHelpIcon,
        paths: ["/admin/faqs", "/admin/faqs/add"],
        urls: [
            {
                url: "/admin/faqs",
                text: "Всички",
            },
            { url: "/admin/faqs/add", text: "Добави" },
        ],
        text: "Въпроси и отговори",
    },
    contacts: {
        icon: ContactEmergencyIcon,
        paths: ["/admin/contactform"],
        urls: [
            {
                url: "/admin/contactform",
                text: "Всички",
            },
        ],
        text: "Контактна форма",
    },
    newsletter: {
        icon: NewspaperIcon,
        paths: ["/admin/newsletter"],
        urls: [
            {
                url: "/admin/newsletter",
                text: "Всички",
            },
        ],
        text: "Бюлетин",
    },
    companies: {
        icon: ApartmentIcon,
        paths: ["/admin/company", "/admin/company/add"],
        urls: [
            {
                url: "/admin/company",
                text: "Всички",
            },
            { url: "/admin/company/add", text: "Добави" },
        ],
        text: "Компании",
    },
    logs: {
        icon: LogoDevIcon,
        paths: ["/admin/logs"],
        urls: [
            {
                url: "/admin/logs",
                text: "Всички",
            },
        ],
        text: "Логове",
    },
};
