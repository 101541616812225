const formatDates = (date, short = false) => {
    // Create a new Date object with the current date and time
    const now = new Date(date);

    // Get the individual date and time components
    const day = String(now.getDate()).padStart(2, "0"); // Get the day of the month (padded with leading zero if necessary)
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Get the month (January is 0, so add 1; padded with leading zero if necessary)
    const year = now.getFullYear(); // Get the full year
    const hours = String(now.getHours()).padStart(2, "0"); // Get the hours (padded with leading zero if necessary)
    const minutes = String(now.getMinutes()).padStart(2, "0"); // Get the minutes (padded with leading zero if necessary)
    const seconds = String(now.getSeconds()).padStart(2, "0"); // Get the seconds (padded with leading zero if necessary)

    // Construct the formatted date and time string
    const formattedDate = `${day}/${month}/${year}`;
    const formattedTime = `${hours}:${minutes}:${seconds}`;

    const formattedDateTime = `${formattedDate} ${short ? "" : formattedTime}`;

    return formattedDateTime;
};

const descendingComparator = (a, b, orderBy) => {
    const first = typeof a[orderBy] === "string" ? a[orderBy].toLowerCase() : a[orderBy];
    const second = typeof b[orderBy] === "string" ? b[orderBy].toLowerCase() : b[orderBy];
    if (second < first) {
        return -1;
    }
    if (second > first) {
        return 1;
    }
    return 0;
};

const getComparator = (order, orderBy) => {
    return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
};

const discountStatus = (starting_time, ending_time) => {
    const start = new Date(starting_time);
    const end = new Date(ending_time);
    const current = new Date();

    if (start < current && end > current) return "active";
    if (start > current) return "future";
    if (end < current) return "expired";
};

const customRound = (number) => {
    let rounded = number.toFixed(2);

    return parseFloat(rounded);
};
module.exports = { descendingComparator, getComparator, formatDates, discountStatus, customRound };
