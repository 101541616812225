import { AppBar, IconButton, Menu, MenuItem, Toolbar, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { AccountCircle } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
export const Header = ({ drawerWidth, handleDrawerToggle }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        handleClose();
    }, [location.pathname]);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <AppBar
            position='fixed'
            sx={{
                color: "text.secondary",
                bgcolor: "background.paper",
                opacity: 0.95,
                boxShadow: "none",
                width: { sm: `calc(100% - ${drawerWidth}px)` },
                ml: { sm: `${drawerWidth}px` },
            }}>
            <Toolbar>
                <IconButton
                    color='inherit'
                    aria-label='open drawer'
                    edge='start'
                    onClick={handleDrawerToggle}
                    sx={{ mr: 2, display: { sm: "none" } }}>
                    <MenuIcon />
                </IconButton>
                <Typography variant='h6' noWrap component='div' sx={{ flexGrow: 1 }}>
                    Admin Panel
                </Typography>
                <div>
                    <IconButton
                        size='large'
                        aria-label='account of current user'
                        aria-controls='menu-appbar'
                        aria-haspopup='true'
                        onClick={handleMenu}
                        color='inherit'>
                        <AccountCircle />
                    </IconButton>
                    <Menu
                        id='menu-appbar'
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}>
                        <MenuItem onClick={() => navigate("/admin/logout")}>Logout</MenuItem>
                    </Menu>
                </div>
            </Toolbar>
        </AppBar>
    );
};

Header.propTypes = {
    drawerWidth: PropTypes.number.isRequired,
    handleDrawerToggle: PropTypes.func.isRequired,
};
