import React from "react";
import { Helmet } from "react-helmet";

import { CommentSection } from "./CommentSection/CommentSection";
import { EveryoneCanJoin } from "./EveryoneCanJoin/EveryoneCanJoin";
import { HeroBanner } from "./HeroBanner/HeroBanner";
import { IntroductionSection } from "./IntroductionSection/IntroductionSection";
import { MoreInfoSection } from "./MoreInfoSection/MoreInfoSection";
import { QuickFacts } from "./QuickFacts/QuickFacts";

import style from "./Home.module.css";

const Home = () => {
    return (
        <>
            <Helmet>
                <title>Игрите на града - градска игра със загадки на открито.</title>
                <meta
                    name='description'
                    content='Игрите на града е интерактивна игра със загадки на открито. Пътешествие през историята на града. Една цена – неограничен брой хора. Перфектен ваучер за подарък'
                />
                <meta name='canonical' content='https://igritenagrada.com' />
                <link rel='canonical' href='https://igritenagrada.com' />
                <meta property='og:url' content='https://igritenagrada.com' />
            </Helmet>
            <div className={style.wrapper}>
                {/* ////////////////////////////////////////// Hero banner section ////////////////////////////// */}
                <div className={style.heroBannerSection}>
                    <HeroBanner></HeroBanner>
                </div>

                {/* ////////////////////////////////////////// Introduction section //////////////////////////////  */}
                <div className={style.introductionSection}>
                    <IntroductionSection></IntroductionSection>
                </div>

                {/* ////////////////////////////////////////// Everyone can join section ////////////////////////////// */}
                <div className={style.everyoneSection}>
                    <EveryoneCanJoin></EveryoneCanJoin>
                </div>

                {/* ////////////////////////////////////////// Quick facts section ////////////////////////////// */}
                <div>
                    <QuickFacts></QuickFacts>
                </div>

                {/* ////////////////////////////////////////// User flow information section ////////////////////////////// */}
                <div className={style.flowImageSection}>
                    <img className={`${style.flowImage} ${style.desktop}`} alt='flow' src='/flow.webp'></img>
                    <img className={`${style.flowImage} ${style.mobile}`} alt='flow' src='/flow-mobile.svg'></img>
                </div>

                {/* ////////////////////////////////////////// More information section ////////////////////////////// */}
                <div className={style.moreInfoSection}>
                    <MoreInfoSection></MoreInfoSection>
                </div>

                {/* ////////////////////////////////////////// Comments section ////////////////////////////// */}
                <div className={style.commentSection}>
                    <CommentSection></CommentSection>
                </div>
            </div>
        </>
    );
};

export default Home;
