import style from "./MoreInfoSection.module.css";

export const MoreInfoSection = () => {
    return (
        <div className={style.textWrapper}>
            <h2 className={style.title}>Градските истории никога не са били по-достъпни</h2>
            <p className={style.text}>
                Ние сме група ентусиасти, стремящи се да превърнат опознаването на града и проследяването на нишката на
                неговата история в достъпно приключение. Нашата цел е да ти дадем свободата да превърнеш всяко кътче на
                града в интерактивно историческо игрално поле.{" "}
            </p>
            <p className={style.text}>
                <span className={style.pTitleMobile}>За да се потопиш в приключението,</span>
                което сме ти подготвили, не ти е нужна регистрация или теглене на приложение. След като закупиш загадка
                от сайта, ще получиш имейл с повече информация и линк за стартиране директно от браузъра на телефона ти.
                Събери отбор с големина по твой избор и стартирай играта, когато пожелаеш.
            </p>
            <p className={style.text}>
                <span className={style.pTitleMobile}>Състезавай се</span>с другите градски авантюристи и завладей върха
                на класацията, като използваш възможно най-малко подсказки и преминаваш през въпросите скорострелно. Ако
                пък целта ти е просто да разпуснеш и да научиш нещо ново, не му мисли – играта няма крайно време и можеш
                да се насладиш на преживяването с твоето темпо.
            </p>
        </div>
    );
};
