import { Link, useNavigate } from "react-router-dom";

import { Button } from "../../Common/Button/Button";

import style from "./EveryoneCanJoin.module.css";

export const EveryoneCanJoin = () => {
    const navigateTo = useNavigate();

    return (
        <>
            <svg width='0' height='0'>
                <defs>
                    <clipPath id='tilted-mask' clipPathUnits='objectBoundingBox'>
                        <path
                            d='M0,0 L0,1
                            C0.03,1 0.07,0.97 0.12,0.98
                            C0.17,1 0.20,0.98 0.25,0.976
                            C0.29,0.97 0.33,0.99 0.40,0.985
                            C0.47,0.97 0.52,0.99 0.54,0.985
                            C0.59,0.975 0.60,0.97 0.62,0.97
                            C0.66,0.965 0.69,0.985 0.73,0.98
                            C0.76,0.975 0.77,0.965 0.80,0.96
                            C0.96,0.95 0.96,1 1,1
                            
                            L1,0.27
                            
                            C0.98,0.26 0.95,0.28 0.93,0.265 
                            C0.90,0.255 0.87,0.22 0.84,0.225
                            C0.80,0.235 0.75,0.18 0.71,0.178
                            C0.69,0.17 0.65,0.22 0.59,0.16
                            C0.57,0.14 0.53,0.15 0.49,0.13
                            C0.45,0.10 0.37,0.18 0.31,0.12
                            C0.23,0.06 0.17,0.12 0.10,0.04
                            C0.06,0.01 0.02,0.00 0,0.02

                             Z               
                            
                            '></path>
                    </clipPath>
                </defs>
            </svg>
            <svg width='0' height='0'>
                <defs>
                    <clipPath id='tilted-mask-mobile' clipPathUnits='objectBoundingBox'>
                        <path
                            d='M0,0 L0,1
                            C0.15,0.96 0.25,0.97 0.40,0.98
                            C0.60,0.98 0.70,0.975 0.80,0.98
                            C0.88,0.98 0.93,0.99 1,0.978
                            
                            L1,0.10
                            
                            C0.90,0.10 0.85,0.065 0.75,0.075 
                            C0.72,0.08 0.66,0.1 0.50,0.065
                            C0.40,0.05 0.35,0.05 0.30,0.05
                            C0.15,0.06 0.10,0.01 0,0

                             Z               
                            
                            '></path>
                    </clipPath>
                </defs>
            </svg>
            <div className={style.content}>
                <div className={style.leftSide}>
                    <h2 className={style.title}>Всеки може да участва</h2>
                    {/* <div className={`${style.elementContainer} ${style.mobile}`}>
                        <div className={style.row}>
                            <div className={style.element}>
                                <div className={style.imageDiv}>
                                    <img className={style.elementImg} alt='tourists' src='/car.svg'></img>
                                </div>
                                <p className={style.text}>туристи</p>
                            </div>
                            <div className={style.element}>
                                <div className={style.imageDiv}>
                                    <img className={style.elementImg} alt='friends' src='/friends.svg'></img>
                                </div>
                                <p className={style.text}>приятели</p>
                            </div>
                            <div className={style.element}>
                                <div className={style.imageDiv}>
                                    <img className={style.elementImg} alt='family' src='/family.svg'></img>
                                </div>
                                <p className={style.text}>семейства</p>
                            </div>
                            <div className={style.element}>
                                <div className={style.imageDiv}>
                                    <img className={style.elementImg} alt='aliens' src='/aliens.svg'></img>
                                </div>
                                <p className={style.text}>извънземни</p>
                            </div>
                        </div>
                    </div> */}
                    <div className={`${style.iconDiv} ${style.mobile}`}>
                        <img className={style.iconGroup} src='/iconsGroup.svg' alt='icons'></img>
                    </div>
                    <p className={style.text}>
                        Независимо дали си любител на историята и градските легенди или просто искаш да избягаш от
                        ежедневието, тази игра е за теб. Без оглед на възрастта или интересите ти, всичко от което се
                        нуждаеш е бистър ум, мобилен телефон и желание за приключения. Забавлявай се с любимите си хора.
                        С твоето темпо. Ако искаш да разбереш повече, виж във{" "}
                        <span className={style.linkSpan}>
                            <Link className={style.link} to='/faq'>
                                Въпроси и отговори
                            </Link>
                        </span>
                        .
                    </p>
                    <div className={style.desktop}>
                        <Button navigate={"/catalogue"} version={"version-2"}></Button>
                    </div>
                    <div className={style.mobile}>
                        <Button navigate={"/catalogue"} version={"version-10"}></Button>
                    </div>
                </div>

                <div className={`${style.rightSide} ${style.desktop}`}>
                    <div className={style.elementContainer}>
                        <div className={style.row}>
                            <div className={style.element}>
                                <div className={style.imageDiv}>
                                    <img className={style.elementImg} alt='tourists' src='/car.svg'></img>
                                </div>
                                <p className={style.text}>туристи</p>
                            </div>
                            <div className={style.element}>
                                <div className={style.imageDiv}>
                                    <img className={style.elementImg} alt='friends' src='/friends.svg'></img>
                                </div>
                                <p className={style.text}>приятели</p>
                            </div>
                        </div>
                        <div className={style.row}>
                            <div className={style.element}>
                                <div className={style.imageDiv}>
                                    <img className={style.elementImg} alt='family' src='/family.svg'></img>
                                </div>
                                <p className={style.text}>семейства</p>
                            </div>
                            <div className={style.element}>
                                <div className={style.imageDiv}>
                                    <img className={style.elementImg} alt='aliens' src='/aliens.svg'></img>
                                </div>
                                <p className={style.text}>извънземни</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
