import React from "react";
import { Snackbar, Alert } from "@mui/material";
import { useAlert } from "./../../../context/adminAlertContext";

const AdminAlert = ({ children }) => {
    const { alert, closeAlert } = useAlert();

    return (
        <div>
            {children}
            <Snackbar
                open={alert.open}
                autoHideDuration={6000}
                onClose={closeAlert}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}>
                <Alert onClose={closeAlert} severity={alert.severity} sx={{ width: "100%" }}>
                    {alert.message}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default AdminAlert;
