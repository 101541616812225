const grey = {
    0: "#FFFFFF",
    100: "#F9FAFB",
    200: "#F4F6F8",
    300: "#DFE3E8",
    400: "#C4CDD5",
    500: "#919EAB",
    600: "#637381",
    700: "#454F5B",
    800: "#212B36",
    900: "#161C24",
};

const error = {
    main: "#FF5A35",
};

const success = {
    main: "#4caf50",
};

const primary = {
    main: "#002950",
};

const secondary = {
    main: "#FFD000",
};
const action = {
    active: "#001E3C",
    disabled: "#CDD2D6",
};

const base = {
    primary,
    secondary,
    success,
    error,
    grey,
    action,
};
export const palette = () => {
    return {
        ...base,
        background: {
            paper: "#fff",
            default: grey[100],
            neutral: grey[200],
        },
        text: {
            primary: grey[800],
            secondary: grey[600],
            disabled: grey[500],
        },

        disabled: "#919EAB",
    };
};
