import React, { useEffect, useRef } from "react";

import style from "./Map.module.css";

export const Map = (params) => {
    const modalRef = useRef();

    // Event to hide modal if clicked outside of it
    const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            params.setShowMap(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <>
            {params.showMap && <div className={style.backdrop} />}
            <div className={`${style.modal} ${params.showMap ? style.show : ""}`} ref={modalRef}>
                <div className={style.mapSection}>
                    <div onClick={() => params.setShowMap(false)} className={style.exitWrapper}>
                        <img className={style.exit} src='/map-exit.svg'></img>
                    </div>
                    <iframe className={style.map} src={params.map}></iframe>
                </div>
            </div>
        </>
    );
};
