import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import style from "./Navbar.module.css";

export const Navbar = () => {
    const location = useLocation();
    const navigateTo = useNavigate();

    const [pathName, setPathName] = useState("/");

    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.scrollY;
            setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 100);

            setPrevScrollPos(currentScrollPos);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [prevScrollPos]);

    useEffect(() => {
        setPathName(window.location.pathname);
    }, [location]);

    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };
    const closeMenu = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        document.addEventListener("click", closeMenu);
        document.addEventListener("scroll", closeMenu);
        return () => {
            document.removeEventListener("click", closeMenu);
            document.removeEventListener("scroll", closeMenu);
        };
    }, []);

    return (
        <>
            <div className={style.emptyDiv}></div>
            <div className={`${style.wrapper} ${visible ? style.visible : style.hidden}`}>
                {/* Warning message visible into the nav section */}
                {/* <div className={style.warning}>
                    В момента се провеждат само тестови игри. Разплащателната система Stripe, е в тестови режим. Не
                    опитвайте да правите покупки с валидни картови данни!
                </div> */}
                <div className={style.navWrapper}>
                    <div className={style.logoSection}>
                        {/* <p>logo</p> */}
                        <img
                            className={style.logo}
                            alt='logo'
                            src='/logo-primary-row.svg'
                            onClick={() => navigateTo("/")}></img>
                    </div>

                    <div
                        className={`${style.navbar} ${isOpen ? style.open : ""} ${style.mobile}`}
                        onClick={(e) => e.stopPropagation()}>
                        <div className={style.menuToggle} onClick={toggleMenu}>
                            <div className={style.line}></div>
                            <div className={style.line}></div>
                            <div className={style.line}></div>
                        </div>
                        <div className={style.menuContent} onClick={(e) => e.stopPropagation()}>
                            {/* Your menu items go here */}
                            <Link to='/' className={style.itemText} onClick={() => closeMenu()}>
                                начало
                            </Link>
                            <Link to='/catalogue' className={style.itemText} onClick={() => closeMenu()}>
                                загадки
                            </Link>
                            <Link
                                to='/faq'
                                className={style.itemText}
                                onClick={() => {
                                    closeMenu();
                                }}>
                                въпроси и отговори
                            </Link>
                        </div>
                    </div>

                    <div className={`${style.menu} ${style.desktop}`}>
                        <ul className={style.ul}>
                            <li className={style.item}>
                                <Link to='/' className={style.itemText}>
                                    начало
                                </Link>
                                <div className={`${style.highlighter} ${pathName === "/" ? null : style.hide}`}></div>
                            </li>
                            <li className={style.item}>
                                <Link to='/catalogue' className={style.itemText}>
                                    загадки
                                </Link>
                                <div
                                    className={`${style.highlighter} ${
                                        pathName === "/catalogue" ? null : style.hide
                                    }`}></div>
                            </li>
                            <li className={style.item}>
                                <Link to='/faq' className={style.itemText}>
                                    въпроси и отговори
                                </Link>
                                <div
                                    className={`${style.highlighter} ${pathName === "/faq" ? null : style.hide}`}></div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};
