import * as request from "./requester";

let baseUrl = `${process.env.REACT_APP_SERVER_URL}/event`;

export const getCurrentState = (token) => request.get(`${baseUrl}/${token}`);

export const startEvent = (token, teamName) => request.post(`${baseUrl}/${token}/start`, teamName);

export const getHint = (token) => request.get(`${baseUrl}/${token}/hint`);

export const skipQuestion = (token) => request.post(`${baseUrl}/${token}/skip`);

export const validateAnswer = (token, answer) => request.post(`${baseUrl}/${token}`, answer);

export const getAll = () => request.get(baseUrl);

export const getEventById = (id) => request.get(`${baseUrl}/admin/${id}`);

export const createEvent = (data) => request.post(`${baseUrl}/admin/create`, data);
