import { useState } from "react";
import PropTypes from "prop-types";

import { Button } from "./../../Common/Button/Button";
import { Checkbox } from "../../Common/Checkbox/Checkbox";
import { Tutorial } from "../Tutorial/Turorial";

import style from "./StartStage.module.css";

export const StartStage = ({ startEventMutation, eventData }) => {
    const [shakePrivacy, setShakePrivacy] = useState(false);
    const [isCheckedPrivacy, setIsCheckedPrivacy] = useState(false);
    const [shakeRules, setShakeRules] = useState(false);
    const [isCheckedRules, setIsCheckedRules] = useState(false);
    const [shakeTerms, setShakeTerms] = useState(false);
    const [isCheckedTerms, setIsCheckedTerms] = useState(false);

    const [resetCheckbox, setResetCheckbox] = useState(false);

    const [tutorialStage, setTutorialStage] = useState(false);

    const [teamNameInput, setTeamNameInput] = useState("");
    const [teamNameInputError, setTeamNameInputError] = useState(false);

    const checkCheckboxes = () => {
        if (isCheckedPrivacy && isCheckedRules && isCheckedTerms && teamNameInput.length > 1) {
            return true;
        }
        if (!isCheckedPrivacy) setShakePrivacy(true);
        if (!isCheckedRules) setShakeRules(true);
        if (!isCheckedTerms) setShakeTerms(true);
        if (teamNameInput.length <= 1) setTeamNameInputError(true);
        return false;
    };

    const nextStage = () => {
        if (checkCheckboxes()) setTutorialStage(true);
    };

    const start = () => {
        if (checkCheckboxes()) {
            startEventMutation.mutate(teamNameInput);
        }
    };

    const startStage = () => {
        return (
            <div className={style.startStageSection}>
                <div className={style.logoMobileSection}>
                    <img className={style.logo} alt='logo' src='/logo-primary-row-white.svg'></img>
                </div>

                <div className={style.startStageWrapper}>
                    <div className={style.logoSection}>
                        <img alt='logo' src='/logo-primary-row-white.svg'></img>
                    </div>
                    <div className={style.riddleTitle}>
                        {eventData.riddle_title}
                        <div className={style.titleUnderline}></div>
                    </div>
                    <div className={style.textWrapper}>
                        <p className={style.text}>Желаете ли да стартирате играта?</p>
                        <p className={style.text}>След като започнете играта, таймерът ще се включи и няма да можете да го поставите на пауза. </p>
                        <p className={style.text}>Ако стартирате играта, това е моментът да измислите име на отбора си:</p>
                    </div>
                    <div className={style.inputWrapper}>
                        <input
                            className={`${style.teamNameInput} ${teamNameInputError ? style.error : null}`}
                            placeholder='Име на отбор'
                            value={teamNameInput}
                            onChange={(e) => setTeamNameInput(e.target.value)}
                            onFocus={() => setTeamNameInputError(false)}></input>
                        {teamNameInputError ? <p className={style.inputError}>Името трябва да е с дължина, поне 2 символа.</p> : null}
                    </div>

                    <div className={`${style.warning} ${style.desktop}`}>
                        <p className={style.warningText}>
                            <span className={style.warningTextBold}>Важно:</span> Играта не е предвидена за десктоп версия. Някакво още пояснение тук. Може да не могат да
                            се достигнат всички функции на играта.
                        </p>
                    </div>

                    <div className={style.warning}>
                        <p className={style.warningText}>
                            <span className={style.warningTextBold}>Важно:</span> Не попълвайте поверителна информация или лични данни в името на отбора.
                        </p>
                    </div>
                    <div className={style.warning}>
                        <p className={style.warningText}>
                            <span className={style.warningTextBold}>Важно:</span> Не влизайте в частна собственост или каквито и да е други сгради.
                        </p>
                    </div>

                    <div className={style.checkboxSection}>
                        <Checkbox
                            shake={shakePrivacy}
                            setShake={setShakePrivacy}
                            isChecked={isCheckedPrivacy}
                            setIsChecked={setIsCheckedPrivacy}
                            reset={resetCheckbox}
                            label='privacy'
                            haveLink={true}
                            pointA={38}
                            pointB={65}
                            link='https://igritenagrada.com/privacy'
                            linkColor='yellow'
                            text='Прочетох и разбрах условията в секция “Политика за поверителност”'></Checkbox>

                        <Checkbox
                            shake={shakeRules}
                            setShake={setShakeRules}
                            isChecked={isCheckedRules}
                            setIsChecked={setIsCheckedRules}
                            reset={resetCheckbox}
                            label='rules'
                            haveLink={true}
                            pointA={38}
                            pointB={57}
                            link='https://igritenagrada.com/rules'
                            linkColor='yellow'
                            text='Прочетох и разбрах условията в секция “Правила на играта”'></Checkbox>
                        <Checkbox
                            shake={shakeTerms}
                            setShake={setShakeTerms}
                            isChecked={isCheckedTerms}
                            setIsChecked={setIsCheckedTerms}
                            reset={resetCheckbox}
                            label='terms'
                            haveLink={true}
                            pointA={38}
                            pointB={57}
                            link='https://igritenagrada.com/terms'
                            linkColor='yellow'
                            text='Прочетох и разбрах условията в секция “Общи условия”'></Checkbox>
                    </div>
                    <div className={style.buttonWrapper}>
                        <Button onClick={() => nextStage()} version='version-3' text='Продължи'></Button>
                    </div>
                </div>
            </div>
        );
    };

    return tutorialStage ? <Tutorial start={start}></Tutorial> : startStage();
};

StartStage.propTypes = {
    startEventMutation: PropTypes.object.isRequired,
    eventData: PropTypes.object.isRequired,
};
