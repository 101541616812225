import { useEffect, useState, useMemo, useCallback } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

import { FunFact } from "./FunFact/FunFact";
import { GeneralBackground } from "./GeneralBackground/GeneralBackground";
import { Question } from "./Question/Question";
import * as eventService from "../../../service/eventService";
import EventReloadPage from "../EventReloadPage/EventReloadPage";
import PropTypes from "prop-types";

import style from "./ActionStage.module.css";

export const ActionStage = ({ event, reloadEvent }) => {
    const { token } = useParams();
    const queryClient = useQueryClient();

    const [stage, setStage] = useState(event.question.index === 1 ? 1 : 2);
    const [skipState, setSkipState] = useState(false);
    const [funFactInfo, setFunFactInfo] = useState({ answer: "", fun_fact: "", guidelines: "" });
    const [incorrectAnswer, setIncorrectAnswer] = useState(false);

    const handleValidationSuccess = useCallback((res) => {
        if (res.status === "success") {
            if (res.answer === "correct") {
                setFunFactInfo({ answer: "", guidelines: "", fun_fact: res.fun_fact });
                setStage(3);
            } else if (res.answer === "incorrect") {
                setIncorrectAnswer(true);
            }
        }
    }, []);

    const validateAnswerMutation = useMutation({
        mutationFn: (answer) => eventService.validateAnswer(token, { answer }),
        onSuccess: handleValidationSuccess,
        retry: 3,
        retryDelay: (attempt) => Math.min(1000 * 2 ** attempt, 30000),
        refetchOnWindowFocus: false,
    });

    const getHintMutation = useMutation({
        mutationFn: () => eventService.getHint(token),
        onSuccess: (res) => {
            if (res.status === "success") {
                queryClient.invalidateQueries({ queryKey: ["eventStageData", token] });
            } else {
                alert(res.message);
            }
        },
        retry: 3,
        retryDelay: (attempt) => Math.min(1000 * 2 ** attempt, 30000),
        refetchOnWindowFocus: false,
    });

    const skipQuestionMutation = useMutation({
        mutationFn: () => eventService.skipQuestion(token),
        onSuccess: (res) => {
            if (res.status === "success" && res.skip) {
                setFunFactInfo(res.skip);
                setSkipState(true);
            } else {
                alert(res.message);
            }
        },
    });

    const changeFunFactState = useCallback(() => {
        setSkipState(false);
        reloadEvent();
        setStage(2);
    }, [reloadEvent]);

    useEffect(() => {
        if (skipState) {
            setStage(3);
        }
    }, [skipState]);

    const renderContent = useMemo(() => {
        if (event.question.index === 1 && stage === 1) {
            return <GeneralBackground event={event} setStage={setStage} />;
        } else if (stage === 2) {
            return (
                <Question
                    incorrectAnswer={incorrectAnswer}
                    setIncorrectAnswer={setIncorrectAnswer}
                    validateAnswerMutation={validateAnswerMutation}
                    getHintMutation={getHintMutation}
                    skipQuestionMutation={skipQuestionMutation}
                    event={event}
                />
            );
        } else if (stage === 3) {
            return <FunFact skipState={skipState} funFactInfo={funFactInfo} changeFunFactState={changeFunFactState} />;
        }
        return null;
    }, [event, stage, incorrectAnswer, validateAnswerMutation, getHintMutation, skipQuestionMutation, funFactInfo, skipState, changeFunFactState]);

    if (validateAnswerMutation.isError || getHintMutation.isError || skipQuestionMutation.isError) {
        return <EventReloadPage />;
    }

    return <div className={style.actionStageSection}>{renderContent}</div>;
};

ActionStage.propTypes = {
    event: PropTypes.object.isRequired,
    reloadEvent: PropTypes.func.isRequired,
};
