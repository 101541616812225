import { useEffect, useState } from "react";

import style from "./Checkbox.module.css";

export const Checkbox = (params) => {
    const [isChecked, setIsChecked] = useState(false);
    const [shouldShake, setShouldShake] = useState(false);

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
        params.setIsChecked(event.target.checked);
        if (event.target.checked === true) setShouldShake(false);
    };

    useEffect(() => {
        if (params.shake) {
            if (!isChecked) {
                // Trigger shake animation
                setShouldShake(true);
                // Remove the class after the animation completes (assuming 1s duration)
                setTimeout(() => {
                    params.setShake(false);
                    setShouldShake(false);
                }, 1000);
            }
        }
    }, [params.shake]);

    useEffect(() => {
        setIsChecked(false);
    }, [params.reset]);

    return (
        <label htmlFor={params.label} className={`${style.customCheckbox} ${shouldShake ? style.shake : ""}`}>
            <input
                type='checkbox'
                checked={isChecked}
                onChange={handleCheckboxChange}
                id={params.label}
                name={params.label}
            />
            <div className={style.test}>
                <span className={style.checkmark}></span>
            </div>
            {params.haveLink ? (
                <p className={style.checkboxText}>
                    {params.text.slice(0, params.pointA)}{" "}
                    <a className={style[params.linkColor]} target='_blank' rel='noopener noreferrer' href={params.link}>
                        {params.text.slice(params.pointA, params.pointB)}
                    </a>{" "}
                    {/* {params.text.slice(params.pointB)} */}
                </p>
            ) : (
                <p className={style.checkboxText}>{params.text}</p>
            )}
        </label>
    );
};
