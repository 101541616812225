import { lazy } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { RouterProvider, Route, createBrowserRouter, createRoutesFromElements } from "react-router-dom";

import Admin from "./components/AdminPanel/Admin/Admin";
import Catalogue from "./components/Catalogue/Catalogue";
import Event from "./components/Event/Event";
import Home from "./components/Home/Home";
import Rank from "./components/Rank/Rank";
import Root from "./root";

const Checkout = lazy(() => import("./components/Checkout/Checkout"));
const EventCreatePage = lazy(() => import("./pages/adminPanel/Event/EventCreatePage"));
const EventListingPage = lazy(() => import("./pages/adminPanel/Event/EventListingPage.js"));
const EventDetailsPage = lazy(() => import("./pages/adminPanel/Event/EventDetailsPage.js"));
const CompanyAddPage = lazy(() => import("./pages/adminPanel/Company/CompanyAddPage"));
const CompanyDetailsPage = lazy(() => import("./pages/adminPanel/Company/CompanyDetailsPage"));
const CompanyEditPage = lazy(() => import("./pages/adminPanel/Company/CompanyEditPage"));
const CompanyListingPage = lazy(() => import("./pages/adminPanel/Company/CompanyListingPage"));
const LogsPage = lazy(() => import("./pages/adminPanel/UserLogs/LogsPage.js"));
const ContactFormListingPage = lazy(() => import("./pages/adminPanel/ContactForm/ContactFormListingPage.js"));
const ContactFormDetailsPage = lazy(() => import("./pages/adminPanel/ContactForm/ContactFormDetailsPage.js"));
const Dashboard = lazy(() => import("./components/AdminPanel/Dashboard/Dashboard"));
const DiscountAddPage = lazy(() => import("./pages/adminPanel/Discounts/DiscountAddPage"));
const DiscountDetailsPage = lazy(() => import("./pages/adminPanel/Discounts/DiscountDetailsPage"));
const DiscountListingPage = lazy(() => import("./pages/adminPanel/Discounts/DiscountListingPage"));
const DiscountEditPage = lazy(() => import("./pages/adminPanel/Discounts/DiscountEditPage"));
const EventDetails = lazy(() => import("./components/AdminPanel/Events/EventDetails/EventDetails"));
const FaqListingPage = lazy(() => import("./pages/adminPanel/Faq/FaqListingPage"));
const FaqDetailsPage = lazy(() => import("./pages/adminPanel/Faq/FaqDetailsPage"));
const FaqAddPage = lazy(() => import("./pages/adminPanel/Faq/FaqAddPage"));
const FaqEditPage = lazy(() => import("./pages/adminPanel/Faq/FaqEditPage"));
const Faq = lazy(() => import("./components/Faq/Faq.js"));
const LoginForm = lazy(() => import("./components/AdminPanel/LoginForm/LoginForm"));
const NewsletterListingPage = lazy(() => import("./pages/adminPanel/Newsletter/NewsletterListingPage"));
const NotFound = lazy(() => import("./components/404/404"));
const OrderDetails = lazy(() => import("./components/AdminPanel/Orders/OrderDetails/OrderDetails"));
const OrderListingPage = lazy(() => import("./pages/adminPanel/Orders/OrdersListingPage.js"));
const OrderDetailsPage = lazy(() => import("./pages/adminPanel/Orders/OrderDetailsPage.js"));
const Privacy = lazy(() => import("./components/TermsAndPrivacy/Privacy/Privacy"));
const RiddleListingPage = lazy(() => import("./pages/adminPanel/Riddles/RiddleListingPage"));
const RiddleEditPage = lazy(() => import("./pages/adminPanel/Riddles/RiddleEditPage.js"));
const RiddleAddPage = lazy(() => import("./pages/adminPanel/Riddles/RiddleAddPage.js"));
const RiddleDetailsPage = lazy(() => import("./pages/adminPanel/Riddles/RiddleDetailsPage"));
const Rules = lazy(() => import("./components/TermsAndPrivacy/Rules/Rules"));
const SuccessPaymentPage = lazy(() => import("./components/SuccessPaymentPage/SuccessPaymentPage"));
const Terms = lazy(() => import("./components/TermsAndPrivacy/Terms/Terms"));
const Cookies = lazy(() => import("./components/TermsAndPrivacy/Cookies/Cookies"));
const Logout = lazy(() => import("./components/AdminPanel/Logout/Logout"));

function App() {
    const router = createBrowserRouter(
        createRoutesFromElements(
            <>
                <Route path='/admin' element={<Admin></Admin>}>
                    <Route path='/admin/login' element={<LoginForm></LoginForm>}></Route>
                    <Route path='/admin/logout' element={<Logout></Logout>}></Route>
                    <Route path='/admin' element={<Dashboard />}></Route>
                    <Route path='/admin/riddles' element={<RiddleListingPage />}></Route>
                    <Route path='/admin/riddles/details/:id' element={<RiddleDetailsPage />}></Route>
                    <Route path='/admin/riddles/edit/:id' element={<RiddleEditPage />}></Route>
                    <Route path='/admin/riddles/add' element={<RiddleAddPage />}></Route>
                    <Route path='/admin/orders/details/:id' element={<OrderDetailsPage />}></Route>
                    <Route path='/admin/orders' element={<OrderListingPage />}></Route>
                    <Route path='/admin/orders/:id' element={<OrderDetails />}></Route>
                    <Route path='/admin/events' element={<EventListingPage />}></Route>
                    <Route path='/admin/events/details/:id' element={<EventDetailsPage />}></Route>
                    <Route path='/admin/events/create' element={<EventCreatePage />}></Route>
                    <Route path='/admin/events/:id' element={<EventDetails />}></Route>
                    <Route path='/admin/newsletter' element={<NewsletterListingPage />}></Route>
                    <Route path='/admin/contactform' element={<ContactFormListingPage />}></Route>
                    <Route path='/admin/contactform/details/:id' element={<ContactFormDetailsPage />}></Route>
                    <Route path='/admin/faqs' element={<FaqListingPage />}></Route>
                    <Route path='/admin/faqs/edit/:id' element={<FaqEditPage />}></Route>
                    <Route path='/admin/faqs/details/:id' element={<FaqDetailsPage />}></Route>
                    <Route path='/admin/faqs/add' element={<FaqAddPage />}></Route>
                    <Route path='/admin/discounts' element={<DiscountListingPage />}></Route>
                    <Route path='/admin/discounts/details/:id' element={<DiscountDetailsPage />}></Route>
                    <Route path='/admin/discounts/edit/:id' element={<DiscountEditPage />}></Route>
                    <Route path='/admin/discounts/add' element={<DiscountAddPage />}></Route>
                    <Route path='/admin/company' element={<CompanyListingPage />}></Route>
                    <Route path='/admin/company/add' element={<CompanyAddPage />}></Route>
                    <Route path='/admin/company/details/:id' element={<CompanyDetailsPage />}></Route>
                    <Route path='/admin/company/edit/:id' element={<CompanyEditPage />}></Route>
                    <Route path='/admin/logs' element={<LogsPage />}></Route>
                </Route>
                <Route path='/event/:token' element={<Event></Event>}></Route>
                <Route path='/rank' element={<Rank></Rank>}></Route>
                <Route path='/rank/:riddleName' element={<Rank></Rank>}></Route>
                <Route path='/rank/:riddleName/:idx' element={<Rank></Rank>}></Route>
                <Route path='/rank/:riddleName/:companyName/:idx' element={<Rank></Rank>}></Route>
                <Route path='/' element={<Root />}>
                    <Route path='/' element={<Home />}></Route>
                    <Route path='/catalogue' element={<Catalogue />}></Route>
                    <Route path='/faq' element={<Faq />}></Route>
                    <Route path='/checkout/:id' element={<Checkout />}></Route>
                    <Route path='/success' element={<SuccessPaymentPage />}></Route>
                    <Route path='/terms' element={<Terms></Terms>}></Route>
                    <Route path='/privacy' element={<Privacy></Privacy>}></Route>
                    <Route path='/cookies' element={<Cookies></Cookies>}></Route>
                    <Route path='/rules' element={<Rules></Rules>}></Route>
                    <Route path='*' element={<NotFound />}></Route>
                </Route>
            </>
        )
    );
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                staleTime: 60 * 1000 * 5,
            },
        },
    });

    return (
        <QueryClientProvider client={queryClient}>
            <RouterProvider router={router} />
        </QueryClientProvider>
    );
}

export default App;
