import { Button } from "../../Common/Button/Button";
import style from "./EventReloadPage.module.css";
import PropTypes from "prop-types";

const EventReloadPage = ({ reloadEvent }) => {
    const handleReload = () => {
        if (reloadEvent) {
            reloadEvent();
        } else {
            window.location.reload();
        }
    };

    return (
        <div className={style.errorContainer}>
            <img className={style.img} alt='broken link' src='/hyperlink.png' />
            <p className={style.text}>
                Възникна грешка в приложението. Доста вероятно грешката да е в следствие на частично прекъсване на интернет връзката. Пробвайте да презаредете страницата!
            </p>
            <Button version='version-2' text='Презареди' onClick={handleReload} />
        </div>
    );
};

EventReloadPage.propTypes = {
    reloadEvent: PropTypes.func,
};

export default EventReloadPage;
