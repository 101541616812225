import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import { customRound } from "../../utils/utils";
import * as companyService from "../../service/companyService";
import * as rankService from "../../service/rankService";
import EventReloadPage from "./../Event/EventReloadPage/EventReloadPage";
import Loader from "./../Common/Loader/Loader";

import style from "./Rank.module.css";

const Rank = () => {
    const { idx = -1, riddleName, companyName } = useParams();

    let query = "";
    if (riddleName != undefined) query += `&riddle_name=${riddleName}`;
    if (companyName != undefined) query += `&company=${companyName}`;

    const rankQuery = useQuery({
        queryKey: ["rankData"],
        queryFn: () => rankService.getAll(query),
    });
    const companyQuery = useQuery({
        queryKey: ["companyData"],
        queryFn: () => companyService.getOne(companyName),
        enabled: companyName != undefined,
    });

    const formatTime = (timeInSeconds) => {
        const hours = Math.floor(timeInSeconds / 3600);
        const minutes = Math.floor((timeInSeconds % 3600) / 60);
        return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
    };
    const line = (data, index, top3 = undefined) => {
        const showIndex = index == idx && top3 == undefined ? true : false;
        return (
            <div key={index} className={`${style.lineWrapper} ${showIndex ? style.active : null}`}>
                <div className={`${style.line}  ${top3 ? style.mobileTopLineWrapper : null}`}>
                    <p className={style.cl1}>{index}</p>
                    <p className={style.cl2}>{data.team_name}</p>
                    <p className={style.cl3}>{customRound(data.points)} т.</p>
                    <p className={style.cl4}>{formatTime(data.time)} ч.</p>
                </div>
            </div>
        );
    };

    if (rankQuery.isLoading || companyQuery.isLoading) return <Loader />;

    if (rankQuery.isError || companyQuery.isError) return <EventReloadPage />;
    if (rankQuery.data.ranks.length < 1)
        return (
            <div className={style.rankSection}>
                <div className={style.rankWrapper}>
                    <div className={style.titleWrapper}>
                        <p className={style.title}>Класация</p>
                    </div>
                </div>
                <p className={style.text}>Класацията ще бъде видима, когато има поне едни отбор</p>
            </div>
        );

    return (
        <div className={style.rankSection}>
            <Helmet>
                <title>Игрите на града - ранглиста</title>
                <meta
                    name='description'
                    content='Ранглиста за отборите играли игри на Игрите на града. Отборите са сортиране по игри или компании, когато са част от тиймбилдинг събития'
                />
                <meta name='robots' content='noindex' />
                <meta name='canonical' content='https://igritenagrada.com/rank' />
                <link rel='canonical' href='https://igritenagrada.com/rank' />
                <meta property='og:url' content='https://igritenagrada.com/rank' />
            </Helmet>
            <div className={style.rankWrapper}>
                <div className={style.titleWrapper}>
                    <h1 className={style.title}>Класация</h1>
                </div>
                {companyName != undefined ? (
                    <div className={style.companySection}>
                        {companyQuery.data?.company.image ? <img alt='company logo' className={style.companyImg} src={`${process.env.REACT_APP_BASE_SERVER_URL}/${companyQuery.data.company.image}`}></img> : null}
                        {companyQuery.data?.company.company_name ? <h2 className={style.companyName}>{companyQuery.data.company.company_name}</h2> : null}
                    </div>
                ) : null}

                <div className={style.topWrapper}>
                    <img className={style.top1Img} alt='top1' src='/top1.png'></img>
                    <div className={style.top}>
                        <div className={style.top2Wrapper}>
                            <div className={style.box}>
                                <p>2</p>
                            </div>
                            <p className={style.teamNameTop}> {rankQuery.data.ranks[1] ? rankQuery.data.ranks[1].team_name : "-"}</p>
                            <div className={style.mobilePointsWrapper}>
                                <p className={style.pointsMobile}>{rankQuery.data.ranks[1] ? customRound(rankQuery.data.ranks[1].points) : 0} т.</p>
                                <p className={style.timeMobile}> {rankQuery.data.ranks[1] ? formatTime(rankQuery.data.ranks[1].time) : "00:00"} ч.</p>
                            </div>
                        </div>
                        <div className={style.clm}>
                            <div className={style.top1}>
                                <p>1</p>
                            </div>
                            <p className={style.teamNameTop}> {rankQuery.data.ranks[0].team_name}</p>
                            <div className={style.mobilePointsWrapper}>
                                <p className={style.pointsMobile}>{rankQuery.data.ranks[0] ? customRound(rankQuery.data.ranks[0].points) : 0} т.</p>
                                <p className={style.timeMobile}> {rankQuery.data.ranks[0] ? formatTime(rankQuery.data.ranks[0].time) : "00:00"} ч.</p>
                            </div>
                        </div>
                        <div className={style.top3Wrapper}>
                            <div className={style.box}>
                                <p>3</p>
                            </div>
                            <p className={style.teamNameTop}> {rankQuery.data.ranks[2] ? rankQuery.data.ranks[2].team_name : "-"}</p>
                            <div className={style.mobilePointsWrapper}>
                                <p className={style.pointsMobile}>{rankQuery.data.ranks[2] ? customRound(rankQuery.data.ranks[2].points) : 0} т.</p>
                                <p className={style.timeMobile}> {rankQuery.data.ranks[2] ? formatTime(rankQuery.data.ranks[2].time) : "00:00"} ч.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <img className={style.top2Img} alt='top1' src='/top1-desktop.png'></img>

                <div className={style.restWrapper}>
                    <div className={`${style.lineWrapper} ${style.tableTitleRow}`}>
                        <div className={style.line}>
                            <p className={style.cl1}>място</p>
                            <p className={style.cl2}>отбор</p>
                            <p className={style.cl3}>точки</p>
                            <p className={style.cl4}>време</p>
                        </div>
                    </div>
                    <div className={style.mobileTopSection}>{rankQuery.data.ranks.slice(0, 3).map((r, index) => line(r, index + 1, true))}</div>
                    {rankQuery.data.ranks.slice(3).map((r, index) => line(r, index + 4))}
                </div>
            </div>
        </div>
    );
};

export default Rank;
