import React, { useState, useEffect } from "react";

import style from "./Clock.module.css";
export const Clock = (params) => {
    const [elapsedTime, setElapsedTime] = useState("");

    useEffect(() => {
        const startTimeDate = new Date(params.startingTime);
        updateElapsedTime(startTimeDate);
        let timerID = setInterval(() => updateElapsedTime(startTimeDate), 1000);

        return () => clearInterval(timerID);
    }, [params.startingTime]);

    let updateElapsedTime = (startTimeDate) => {
        let now = new Date();
        let elapsed = new Date(now - startTimeDate);
        let hours = elapsed.getUTCHours();
        let minutes = elapsed.getUTCMinutes();
        let sec = elapsed.getUTCSeconds();
        if (hours < 10) hours = "0" + hours;
        if (minutes < 10) minutes = "0" + minutes;
        if (sec < 10) sec = "0" + sec;
        setElapsedTime(`${hours}:${minutes}:${sec} ч.`);
    };

    return <p className={style.clock}>{elapsedTime}</p>;
};
