import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";

import { CatalogueItem } from "./CatalogueItem/CatalogueItem";
import * as riddleService from "../../service/riddleService";

import style from "./Catalogue.module.css";

const Catalogue = () => {
    const [riddles, setRiddles] = useState([]);
    const [highlighter1, setHighlighter1] = useState(true);

    const highlighterRef1 = useRef(null);

    const highlight = (ref, setHighlight, offset) => {
        if (ref.current) {
            const imageRect = ref.current.getBoundingClientRect();
            const imageTop = imageRect.top + offset; // Add the offset
            const imageBottom = imageRect.bottom;

            if (imageTop < window.innerHeight && imageBottom > 0) {
                // Image is in the viewport (with offset consideration)
                setHighlight(false);
            }
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            if (highlighter1) highlight(highlighterRef1, setHighlighter1, 400);
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [highlighter1, highlighterRef1, setHighlighter1]);

    useEffect(() => {
        riddleService
            .getAll()
            .then((res) => {
                if (res.status === "success" && res.riddles.length > 0) setRiddles(res.riddles);
                else alert(res.message);
            })
            .catch((err) => {
                console.log(err);
                alert(err);
            });
    }, []);

    return (
        <>
            <Helmet>
                <title>Игрите на града - загадки</title>
                <meta
                    name='description'
                    content='Разгледай нашите загадки. Впусни се във вълнуващо пътешествие през историята на града ти. Една цена – неограничен брой участници. Перфектен ваучер за подарък'
                />
                <meta name='canonical' content='https://igritenagrada.com/catalogue' />
                <link rel='canonical' href='https://igritenagrada.com/catalogue' />
                <meta property='og:url' content='https://igritenagrada.com/catalogue' />
            </Helmet>
            <div className={style.catalogueWrapper}>
                {/* //////////////////////////////////////////////// Catalogue section //////////////////////// */}

                <svg className='clip-svg' width='0' height='0'>
                    <defs>
                        <clipPath id='bottom-v-1' clipPathUnits='objectBoundingBox'>
                            <path
                                d='M0,0 L0,0.96
                            C0.2,0.88 0.3,1.02 0.5,0.95
                            C0.7,0.90 0.8,1.02 1,0.95 L1,0.23
                            
                            C0.9,0.20 0.8,0.13 0.7,0.15 
                            C0.5,0.20 0.5,0.05 0.2,0.05
                            C0.1,0.05 0.05,0.00 0,0 Z               
                            
                            '></path>
                        </clipPath>
                    </defs>
                </svg>
                <svg className='clip-svg' width='0' height='0'>
                    <defs>
                        <clipPath id='bottom-v-1-mobile' clipPathUnits='objectBoundingBox'>
                            <path
                                d='M0,0 L0,0.96
                            C0.2,0.93 0.3,0.99 0.5,0.97
                            C0.7,0.95 0.8,1.00 1,0.98 
                            
                            L1,0.06
                            
                            C0.9,0.05 0.85,0.06 0.7,0.054 
                            C0.59,0.05 0.5,0.03 0.2,0.03
                            C0.1,0.03 0.05,0.02 0,0.02 Z               
                            
                            '></path>
                        </clipPath>
                    </defs>
                </svg>
                <svg width='0' height='0'>
                    <defs>
                        <clipPath id='gentle-wave-clip-path' clipPathUnits='objectBoundingBox'>
                            <path
                                d='M0,0 L0,0.98 
                            C0.2,0.98 0.3,1.00 0.5,0.99 
                            C0.7,0.97 0.8,1.01 1,0.98 L1,0 Z'
                            />
                        </clipPath>
                    </defs>
                </svg>
                <svg width='0' height='0'>
                    <defs>
                        <clipPath id='gentle-wave-clip-path-mobile' clipPathUnits='objectBoundingBox'>
                            <path
                                d='M0,0 L0,0.99 
                            C0.2,0.99 0.3,1.00 0.5,0.9935 
                            C0.7,0.99 0.8,1 1,0.99 L1,0 Z'
                            />
                        </clipPath>
                    </defs>
                </svg>
                <svg width='0' height='0'>
                    <defs>
                        <clipPath id='top-wave-clip-path' clipPathUnits='objectBoundingBox'>
                            <path
                                d='M0,1 
                     C0.05,0.8 0.15,0.6 0.25,0.6 
                     C0.35,0.6 0.45,0.8 0.5,0.8 
                     C0.55,0.8 0.65,0.6 0.75,0.6 
                     C0.85,0.6 0.95,0.8 1,0.8 
                     V1 
                     Z'
                            />
                        </clipPath>
                    </defs>
                </svg>
                <svg width='0' height='0'>
                    <defs>
                        <clipPath id='top-wave-clip-path-mobile' clipPathUnits='objectBoundingBox'>
                            <path
                                d='M0,1
                             C0.05,0.9 0.15,0.8 0.25,0.85
                             C0.35,0.9 0.45,0.8 0.5,0.8
                             C0.55,0.8 0.65,0.8 0.75,0.75
                             C0.85,0.7 0.95,0.9 1,0.85
                             V1
                             Z'
                            />
                        </clipPath>
                    </defs>
                </svg>

                <h1 className={style.seoTitle}>Нашите загадки</h1>
                {riddles.map((riddle) => (
                    <CatalogueItem key={riddle._id} riddle={riddle}></CatalogueItem>
                ))}

                {/* //////////////////////////////////////////////// How to play section //////////////////////// */}

                <div className={style.howToPlaySection}>
                    <div className={style.howToPlayWrapper}>
                        <div className={style.textWrapper}>
                            <div className={style.title}>
                                <h2 className={style.highlighterSpan} ref={highlighterRef1}>
                                    Как се играе
                                    <div className={`${style.highlighter} ${highlighter1 ? style.hide : null}`}></div>
                                </h2>{" "}
                                ?
                            </div>
                            <p className={style.text}>
                                След като закупиш загадка от сайта, ще получиш имейл с повече информация и линк за стартиране. Играе се директно от браузъра на телефона
                                ти. Не е необходима регистрация, нито изтегляне на приложение, но ще ти е нужен достатъчно бърз интернет и заредена батерия на телефона.
                                Събери отбор с големина по твой избор и стартирай играта, когато пожелаеш.
                            </p>
                            <p className={style.text}>
                                <span className={style.pTitle}> Загадките се развиват в градска среда,</span> на публично достъпни места. Играта няма крайно време, но
                                бързото решаване на загадките ще те издигне в класацията на отборите. След стартиране на загадката, времето ти започва да тече и не може
                                да бъде паузирано. Всяка загадка се състои от различен брой въпроси, свързани логически в история. Всеки въпрос дава точки, съобразени със
                                сложността му.
                            </p>
                            <p className={style.text}>
                                <span className={style.pTitle}>За всеки от въпросите</span>
                                има предвидени по няколко подсказки (хинтове), като за всяка игра има и максимален брой подсказки. Използвай ги пестеливо, тъй като всяка
                                подсказка намалява броя точки, които ще получиш за съответния въпрос и ще се отрази на мястото ти в класацията. Ако се затрудняваш
                                извънредно с някой от въпросите, имаш възможност да го пропуснеш, но няма да получиш точки за него и не можеш да се върнеш към него на
                                по-късен етап. За по-подробна информация виж{" "}
                                <span className={`${style.spanLink} ${style.blueLink}`}>
                                    <Link to='/rules' className={style.links}>
                                        Правила на играта
                                    </Link>
                                </span>
                                .{" "}
                            </p>
                        </div>
                        <img className={style.appMock} src='/app-mock.png' alt='phone mockup'></img>
                    </div>
                </div>
                {/* //////////////////////////////////////// More information about the game ///////////////////// */}

                <div className={style.moreInfoSection}>
                    <div className={style.moreInfoWrapper}>
                        <h2 className={style.title}>Ключова информация за играта</h2>
                        <p className={style.text}>
                            Игрите на града е игра, в която интеракцията с градските пространства те повежда на вълнуващо пътешествие през историята. Загадките се решават
                            през браузъра на телефона ти, но играта се развива изцяло на открито. Съприкосновението с улиците, сградите и символите на града ти е ключова
                            част от преживяването. Целта на играта е да намериш в реалния свят уликите, които ще ти помогнат да достигнеш до правилните отговори, като
                            пътят до тях преминава през разнообразни логически задачи. <span className={style.moreInfoMobileTitle}>За оптимално преживяване</span>{" "}
                            препоръчваме да си предвидиш достатъчно време и да играеш през деня. За всяка загадка сме препоръчали оптимален брой играчи, съобразно
                            продължителността и степента на трудност, но оставяме на теб свободата да решиш в какъв формат да бъде отборът ти. Повече информация за играта
                            може да намериш във{" "}
                            <span className={`${style.spanLink} ${style.yellowLink}`}>
                                <Link to='/faq'>Въпроси и отговори</Link>
                            </span>
                            .{" "}
                        </p>
                    </div>
                </div>
            </div>

            {/* ////////////////////////////////////////// Background window /////////////////// */}

            <div className={style.backgroundWindow}></div>

            {/* ///////////////////////////////////////// Footer wave mask //////////////////////*/}

            <div className={style.footerWave}></div>
        </>
    );
};

export default Catalogue;
