import PropTypes from "prop-types";
import React, { useEffect, useRef, useState, useCallback } from "react";

import { Button } from "../../../../Common/Button/Button";

import style from "./Popup.module.css";

export const Popup = ({ eventData, showPopup, setShowPopup, popupContent, points, skipQuestion, getHint }) => {
    const modalRef = useRef();
    const [pointsAfterHint, setPointsAfterHint] = useState(0);

    const handleClickOutside = useCallback(
        (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setShowPopup(false);
            }
        },
        [setShowPopup]
    );

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [handleClickOutside]);

    useEffect(() => {
        const calculatePoints = () => {
            const usedHints = eventData.event.used_hints_for_current_question;
            if (usedHints === 0) return Math.round(points * 0.7 * 10) / 10;
            if (usedHints === 1) return Math.round(points * 0.5 * 10) / 10;
            return points;
        };
        setPointsAfterHint(calculatePoints());
    }, [eventData, points]);
    const displayUseMoreHintsText = eventData.event.used_hints_for_current_question < 2 && eventData.event.number_used_hints < eventData.event.riddle.max_hints;

    return (
        <>
            {showPopup && <div className={style.backdrop} />}
            <div className={`${style.modal} ${showPopup ? style.show : ""}`} ref={modalRef}>
                <div className={style.contentSection}>
                    <div className={style.textWrapper}>
                        {popupContent === "skip" && (
                            <p className={style.text}>
                                Сигурен ли си, че искаш да пропуснеш въпроса? Ако пропуснеш въпроса, няма да получиш точки за него и няма да можеш да се върнеш назад.{" "}
                                {displayUseMoreHintsText ? "Имаш още неизползвани хинтове." : null}
                            </p>
                        )}

                        {popupContent === "hint" && (
                            <p className={style.text}>
                                Сигурен ли си, че искаш да използваш подсказка? Ако използваш - точките които ще получиш от въпроса ще бъдат {pointsAfterHint}
                            </p>
                        )}
                    </div>
                    <div className={style.buttonWrapper}>
                        <Button onClick={() => setShowPopup(false)} version='version-6' text='Върни се' />

                        {popupContent === "skip" && <Button onClick={skipQuestion} version='version-7' text='Продължи' />}

                        {popupContent === "hint" && (
                            <Button
                                onClick={() => {
                                    getHint();
                                    setShowPopup(false);
                                }}
                                version='version-7'
                                text='Продължи'
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

Popup.propTypes = {
    showPopup: PropTypes.bool.isRequired,
    setShowPopup: PropTypes.func.isRequired,
    popupContent: PropTypes.string.isRequired,
    eventData: PropTypes.object.isRequired,
    skipQuestion: PropTypes.func.isRequired,
    getHint: PropTypes.func.isRequired,
};
