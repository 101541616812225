import React, { useRef, useState, useEffect } from "react";

import style from "./CommentSlider.module.css";

export const CommentSLider = () => {
    const sliderRef = useRef(null);
    const [canSlideLeft, setCanSlideLeft] = useState(false);
    const [canSlideRight, setCanSlideRight] = useState(true);
    const [displayButtons, setDisplayButtons] = useState(false);

    const updateButtonStates = () => {
        if (sliderRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = sliderRef.current;
            setCanSlideLeft(scrollLeft > 0);
            setCanSlideRight(scrollLeft < scrollWidth - clientWidth);
        }
    };

    const showCorrectButtons = () => {
        if (window.innerWidth < 900) {
            setDisplayButtons(true);
        } else {
            setDisplayButtons(false);
        }
    };

    useEffect(() => {
        const currentSlider = sliderRef.current;
        updateButtonStates();
        showCorrectButtons();
        // Add event listener for resize and scroll events
        window.addEventListener("resize", updateButtonStates);
        window.addEventListener("resize", showCorrectButtons);
        if (currentSlider) {
            currentSlider.addEventListener("scroll", updateButtonStates);
        }

        // Cleanup
        return () => {
            window.removeEventListener("resize", updateButtonStates);
            window.removeEventListener("resize", showCorrectButtons);
            if (currentSlider) {
                currentSlider.removeEventListener("scroll", updateButtonStates);
            }
        };
    }, []);

    const slide = (direction) => {
        if (sliderRef.current) {
            const firstItem = sliderRef.current.firstChild;
            const itemWidth = firstItem.offsetWidth;
            const style = window.getComputedStyle(firstItem);
            const margin = parseInt(style.marginRight, 10) + parseInt(style.marginLeft, 10);

            const scrollAmount = itemWidth + margin;
            sliderRef.current.scrollLeft += direction === "right" ? scrollAmount : -scrollAmount;
        }
    };

    const buttonSection = () => {
        return (
            <div className={`${style.buttonSection} ${displayButtons ? style.mobile : style.desktop}`}>
                <div className={style.buttonWrapper}>
                    {canSlideLeft ? (
                        <div onClick={() => slide("left")} disabled={!canSlideLeft} className={style.button}>
                            <img className={style.arrow} alt='arrow' src='/blue-left-arrow.svg'></img>
                        </div>
                    ) : (
                        <div onClick={() => slide("left")} disabled={!canSlideLeft} className={style.inactiveButton}>
                            <img className={style.arrow} alt='arrow' src='/blue-left-arrow.svg'></img>
                        </div>
                    )}

                    {canSlideRight ? (
                        <div onClick={() => slide("right")} disabled={!canSlideRight} className={style.button}>
                            <img src='/blue-arrow.svg' alt='arrow'></img>
                        </div>
                    ) : (
                        <div onClick={() => slide("right")} disabled={!canSlideRight} className={style.inactiveButton}>
                            <img src='/blue-arrow.svg' alt='arrow'></img>
                        </div>
                    )}
                </div>
            </div>
        );
    };

    const comments = [
        "Изненадващо поучителна! Открих толкова много интересни места в града!",
        "Лесно, вълнуващо и изключително образователно!",
        `Никога не съм си представяла, че ще търча по улиците с телефона в ръка, търсейки следващата
        улика! Благодаря!`,
        "Тази игра е като исторически escape room на открито!",
        "Отлично семейно забавление. Децата ми вече ме питат кога отново ще играем!",
        "Невероятно изживяване! Играта ми отвори очите за страхотни факти за града!",
        "Семейната ни екскурзия с играта беше незабравима! Препоръчвам!",
    ];

    const commentCard = (commnet, index) => {
        return (
            <div key={index} className={style.commentCard}>
                <p className={style.commentText}>{commnet}</p>
            </div>
        );
    };

    return (
        <>
            {!displayButtons ? buttonSection() : null}
            <div ref={sliderRef} className={style.commentActionSection}>
                {comments.map((comment, index) => commentCard(comment, index))}
            </div>
            {displayButtons ? buttonSection() : null}
        </>
    );
};
