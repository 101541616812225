import { useEffect, useState } from "react";
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";

export const CookiePopup = () => {
    const [hasConsentValue, setHasConsentValue] = useState(false);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    function loadGoogleAnalytics() {
        setHasConsentValue(true);

        console.log("Loading GA");
        const script = document.createElement("script");
        script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GA_ID}`;
        script.async = true;
        document.head.appendChild(script);

        window.dataLayer = window.dataLayer || [];

        function gtag() {
            window.dataLayer.push(arguments);
        }
        gtag("js", new Date());

        gtag("config", `${process.env.REACT_APP_GA_ID}`, {
            page_path: window.location.pathname,
        });
    }

    useEffect(() => {
        setHasConsentValue(!!getCookieConsentValue());
        if (getCookieConsentValue() === "true") {
            loadGoogleAnalytics();
        }
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        // Add event listener with handleResize callback
        window.addEventListener("resize", handleResize);

        // Call handleResize once to initialize screenWidth
        handleResize();
        // Cleanup: remove event listener when component unmounts
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <CookieConsent
            onAccept={loadGoogleAnalytics}
            location='bottom'
            buttonText='Приемам всички'
            declineButtonText='Отказвам'
            enableDeclineButton='true'
            style={{
                fontFamily: "Montserrat",
                fontSize: "18px",
                background: "white",
                marginBottom: "30px",
                margin: "0 5% 30px 5%",
                width: "calc(90% - 80px)",
                borderRadius: "20px",
                color: "black",
                display: "flex",
                flexDirection: "column",
                flex: "unset",
                paddingTop: "32px",
                paddingLeft: "40px",
                paddingRight: "40px",
            }}
            contentStyle={{ flex: "unset", margin: "unset" }}
            buttonStyle={{
                fontFamily: "Montserrat",
                backgroundColor: "#002950",
                color: "white",
                fontSize: "20px",
                borderRadius: "5px",
                padding: "7px 32px",
                margin: screenWidth >= 565 ? "20px 0 32px 10px" : "0px 0 32px 0px",
            }}
            declineButtonStyle={{
                fontFamily: "Montserrat",
                backgroundColor: "white",
                color: "#002950",
                border: "2px solid #002950",
                borderRadius: "5px",
                padding: "5px 30px",
                margin: screenWidth >= 565 ? "20px 10px 32px 0" : "25px 10px 15px 0",
                fontSize: "20px",
            }}
            expires={150}
            cookieSecurity='true'>
            <h2 style={{ fontSize: "18px", fontWeight: "bold", marginBottom: "20px" }}> Искаш ли бисквитка? 🍪 </h2>
            <p style={{ fontSize: "18px" }}>
                Този уебсайт използва бисквитки, за да подобри вашето потребителско изживяване. Чрез продължаване на
                използването му, вие се съгласявате с нашата Политика за бисквитки. Вижте повече в{" "}
                <a href='https://igritenagrada.com/privacy' style={{ fontStyle: "italic", color: "black" }}>
                    Политика за използване на бисквитки
                </a>
                .
            </p>
        </CookieConsent>
    );
};
