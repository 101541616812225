import { Collapse, styled } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { Link, useLocation } from "react-router-dom";
import { useState, Fragment, useContext } from "react";
import Box from "@mui/material/Box";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import Drawer from "@mui/material/Drawer";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";

import { AuthContext } from "../../../context/authContext";
import { Header } from "./Header";
import { navConfig } from "./config-navigation";

const drawerWidth = 300;

export const NewAdminNav = (props) => {
    const { window } = props;
    const [mobileOpen, setMobileOpen] = useState(false);
    const [isClosing, setIsClosing] = useState(false);
    const location = useLocation();
    const { isAdmin } = useContext(AuthContext);

    const [isOpen, setIsOpen] = useState([]);

    const handleClick = (menu) => {
        let updated = [...isOpen];
        if (updated.includes(menu)) updated = updated.filter((element) => element !== menu);
        else updated.push(menu);
        setIsOpen(updated);
    };

    const handleDrawerClose = () => {
        setIsClosing(true);
        setMobileOpen(false);
    };

    const handleDrawerTransitionEnd = () => {
        setIsClosing(false);
    };

    const handleDrawerToggle = () => {
        if (!isClosing) setMobileOpen(!mobileOpen);
    };

    const comparePathNames = (paths) => {
        return paths.includes(location.pathname);
    };

    const closeAllMenus = (menu) => {
        setIsOpen([menu]);
    };

    const StyledListIcon = styled("div")(({ theme, current }) => ({
        backgroundColor: current === "true" ? theme.palette.success.main : theme.palette.action.active,
        borderRadius: "50%",
        padding: "0px",
        width: "4px",
        height: "4px",
        transform: current === "true" ? "scale(2)" : "1",
        transactionX: "2",
        marginLeft: 10,
    }));

    const renderMenu = (paths, key, Icon, text) => {
        const comparedPath = comparePathNames(paths);
        return (
            <ListItem>
                <ListItemButton selected={comparedPath} variant='nav-button' onClick={() => handleClick(key)}>
                    <ListItemIcon>
                        <Icon
                            sx={{
                                color: comparedPath ? "primary.main" : "text.disabled",
                            }}
                        />
                    </ListItemIcon>
                    <ListItemText primary={text} />
                    {isOpen.includes(key) ? (
                        <ExpandMore sx={{ color: "rgb(0, 41, 80)" }} />
                    ) : (
                        <KeyboardArrowRightIcon sx={{ color: "rgb(0, 41, 80)" }} />
                    )}
                </ListItemButton>
            </ListItem>
        );
    };

    const renderSubmenu = (index, key, url, text) => {
        return (
            <ListItemButton
                key={index}
                onClick={() => closeAllMenus(key)}
                component={Link}
                to={url}
                sx={{ borderRadius: "10px" }}>
                <ListItemIcon>
                    <StyledListIcon current={location.pathname === url ? "true" : "false"} />
                </ListItemIcon>
                <ListItemText primary={text} />
            </ListItemButton>
        );
    };

    const renderExpandingNavItems = () => {
        return Object.entries(navConfig).map(([key, value]) => {
            return (
                <Fragment key={key}>
                    {renderMenu(value.paths, key, value.icon, value.text)}
                    {value.urls && (
                        <ListItem sx={{ pt: 0, pb: 0 }}>
                            <Collapse sx={{ width: "100%" }} in={isOpen.includes(key)} timeout='auto' unmountOnExit>
                                {value.urls.map((element, index) =>
                                    renderSubmenu(index, key, element.url, element.text)
                                )}
                            </Collapse>
                        </ListItem>
                    )}
                </Fragment>
            );
        });
    };

    const drawerContent = (
        <div>
            <Toolbar sx={{ justifyContent: "center" }}>
                <img style={{ width: "140px" }} src='/logo-primary-row.svg' alt='test' />
            </Toolbar>
            <List dense>
                <ListItem>
                    <ListItemButton
                        selected={comparePathNames(["/admin"])}
                        variant='nav-button'
                        sx={{ borderRadius: "10px" }}
                        component={Link}
                        to='/admin'>
                        <ListItemIcon>
                            <DashboardOutlinedIcon
                                sx={{
                                    color: comparePathNames(["/admin"]) ? "primary.main" : "text.disabled",
                                }}
                            />
                        </ListItemIcon>
                        <ListItemText primary='dashboard' sx={{ fontSize: "14px" }} />
                    </ListItemButton>
                </ListItem>
                {renderExpandingNavItems()}
            </List>
        </div>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    if (!isAdmin) return <></>;
    return (
        <Box sx={{ display: "flex" }}>
            <Header drawerWidth={drawerWidth} handleDrawerToggle={handleDrawerToggle} />

            <Box
                component='nav'
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label='mailbox folders'>
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    container={container}
                    variant='temporary'
                    open={mobileOpen}
                    onTransitionEnd={handleDrawerTransitionEnd}
                    onClose={handleDrawerClose}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: "block", sm: "none" },
                        "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
                    }}>
                    {drawerContent}
                </Drawer>
                <Drawer
                    variant='permanent'
                    sx={{
                        display: { xs: "none", sm: "block" },
                        "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
                    }}
                    open>
                    {drawerContent}
                </Drawer>
            </Box>
        </Box>
    );
};
