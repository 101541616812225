// const request = async (method, url, data, files = false, fileExport = false) => {
//     try {
//         let headers = {};
//         let user = localStorage.getItem("auth");
//         if (user !== null) {
//             user = JSON.parse(user);
//         }
//         if (user ? user.token : false) {
//             headers = { Authorization: `Bearer ${user.token}` };
//         }
//         let buildRequest;

//         if (method === "GET") {
//             buildRequest = fetch(url, { headers });
//         } else if (files === true) {
//             buildRequest = fetch(url, {
//                 method,
//                 headers: {
//                     ...headers,
//                     "Access-Control-Request-Headers": "Authorization",
//                     "Access-Control-Allow-Origin": "*",
//                 },
//                 body: data,
//             });
//         } else {
//             buildRequest = fetch(url, {
//                 method,
//                 headers: {
//                     ...headers,
//                     "Access-Control-Request-Headers": "Authorization",
//                     "Access-Control-Allow-Origin": "*",
//                     "Content-Type": "Application/json",
//                 },
//                 body: JSON.stringify(data),
//             });
//         }

//         const response = await buildRequest;
//         if (fileExport) return response;

//         const result = await response.json();
//         return result;
//     } catch (error) {
//         console.log(error);
//     }
// };

// export const get = request.bind({}, "GET");
// export const post = request.bind({}, "POST");
// export const put = request.bind({}, "PUT");
// export const del = request.bind({}, "DELETE");
// export const patch = request.bind({}, "PATCH");

/**
 * Send an HTTP request.
 * @param {string} method - HTTP method (GET, POST, PUT, DELETE, PATCH).
 * @param {string} url - The URL to send the request to.
 * @param {Object} [data] - The data to send with the request.
 * @param {boolean} [isFile=false] - Flag to indicate if the request involves file upload.
 * @param {boolean} [fileExport=false] - Flag to indicate if the response should be returned directly (e.g., for file downloads).
 * @returns {Promise<Object|Response>} - The response data or the response object if fileExport is true.
 */
const request = async (method, url, data, isFile = false, fileExport = false) => {
    try {
        const headers = buildHeaders();
        const requestOptions = createRequestOptions(method, headers, data, isFile);

        const response = await fetch(url, requestOptions);

        if (fileExport) return response;

        return await response.json();
    } catch (error) {
        console.error("Request failed", error);
        throw error;
    }
};

/**
 * Build headers for the request.
 * @returns {Object} - The headers object.
 */
const buildHeaders = () => {
    const headers = {};
    const user = JSON.parse(localStorage.getItem("auth"));

    if (user && user.token) {
        headers.Authorization = `Bearer ${user.token}`;
    }

    return headers;
};

/**
 * Create request options object.
 * @param {string} method - HTTP method.
 * @param {Object} headers - Headers object.
 * @param {Object} [data] - Data to be sent with the request.
 * @param {boolean} isFile - Flag to indicate if the request involves file upload.
 * @returns {Object} - The request options object.
 */
const createRequestOptions = (method, headers, data, isFile) => {
    const options = {
        method,
        headers: {
            ...headers,
            "Access-Control-Request-Headers": "Authorization",
            "Access-Control-Allow-Origin": "*",
        },
    };

    if (method !== "GET") {
        if (isFile) {
            options.body = data;
        } else {
            options.headers["Content-Type"] = "application/json";
            options.body = JSON.stringify(data);
        }
    }

    return options;
};

// // Export HTTP method specific functions
export const get = request.bind(null, "GET");
export const post = request.bind(null, "POST");
export const put = request.bind(null, "PUT");
export const del = request.bind(null, "DELETE");
export const patch = request.bind(null, "PATCH");
