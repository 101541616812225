import { createContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import { useLocalStorage } from "./../hooks/useLocalStorage";
import * as authService from "../service/authService";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const userLogin = (authData) => setAuth(authData);
    const userLogout = () => {
        setAuth({});
        navigate("login");
    };

    const [auth, setAuth] = useLocalStorage("auth", {});
    const location = useLocation();
    const navigate = useNavigate();

    const redirectToLogin = (message) => {
        setAuth({});
        navigate("login");
        alert(message);
    };

    const { refetch } = useQuery({
        queryKey: ["authData"],
        queryFn: async () => {
            try {
                const response = await authService.me();

                if (JSON.stringify(auth) === "{}") navigate("login");
                else if (response.status !== "success" && location.pathname !== "admin/login")
                    redirectToLogin(response.message);

                return response;
            } catch (error) {
                throw error;
            }
        },

        enabled: false, // Disable automatic refetching on mount
    });

    useEffect(() => {
        refetch();
    }, [location.pathname, refetch]);

    return (
        <AuthContext.Provider
            value={{
                user: auth,
                userLogin,
                userLogout,
                isAdmin: auth.role === "admin",
            }}>
            {children}
        </AuthContext.Provider>
    );
};
